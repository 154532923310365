import template from './index.html';
import localize from 'localize';
import uuidv4 from 'uuid';

import MissingImage from '../../../img/image-missing.png';
import { MediaQuestionModal } from 'components/modal/media_question_modal';

import { MediaItem } from 'tummylab-protobuf/js/program.v1/media_item_pb';
import videojs from 'video.js';

const strings = {
  en: {
    title: "Media Title",
    description: "Description",
    thumbnail_url: "Thumbnail",
    playback_url: "Media URL",
    questions: "Questions",
    add_question: "Add new question",
    invalid_url: "Invalid URL",
    type: {
      label: "Media Type",
      video: "Video",
      pdf: "PDF",
      image: "Image",
      audio: "Audio",
      web: "Web",
    }
  },
  sv: {
    title: "Mediatitel",
    description: "Beskrivning",
    thumbnail_url: "Miniatyrbild",
    playback_url: "Medialänk",
    questions: "Frågor",
    add_question: "Lägg till ny fråga",
    invalid_url: "Felaktig länk",
    type: {
      label: "Mediatyp",
      video: "Video",
      pdf: "PDF",
      image: "Bild",
      audio: "Ljud",
      web: "Web",
    }
  }
}

export default function Setup({
  mediaItem = undefined,
  isReadonly = false,
  isAdmin = false,
  onUpdated = (updatedItem) => { console.log("Updated media item", updatedItem) }
} = {}) {
  if (mediaItem === undefined || mediaItem == null) {
    mediaItem = new MediaItem();
    mediaItem.setUuid(uuidv4());
    mediaItem.setType(MediaItem.MediaItemType.VIDEO);
  }
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings),
  });
  refreshViews(container, mediaItem, { isReadonly: isReadonly, isAdmin: isAdmin });

  if (isReadonly || !isAdmin) {
    container.querySelectorAll("input").forEach((match) => {
      match.classList.add("is-static");
      match.setAttribute("readonly", "readonly");
    });

    container.querySelectorAll(".hidden-in-readonly").forEach((match) => {
      match.classList.add("is-hidden");
    });

    container.querySelectorAll("textarea").forEach((match) => {
      match.classList.add("is-static");
      match.style.borderStyle = "none";
      const newText = document.createElement("p");
      newText.innerText = match.value;
      match.parentElement.appendChild(newText);
      newText.style.marginTop = "0.35rem";
      match.classList.add("is-hidden");
    });

    container.querySelectorAll("select").forEach((match) => { match.setAttribute("disabled", "disabled"); });
  }

  container.querySelector(".media-questions-add").addEventListener("click", () => {
    console.log("Add new media question to media item", mediaItem.getUuid());

    new MediaQuestionModal(container, {
      onSubmit: (question) => {
        mediaItem.addQuestions(question);
        refreshViews(container, mediaItem, { isReadonly: isReadonly, isAdmin: isAdmin });
      }
    }).show();
  });
  if (isReadonly || !isAdmin) {
    container.querySelector(".media-questions-add").remove();
  }

  container.querySelector(".media-thumbnail-image").addEventListener("error", (ev) => {
    container.querySelector(".media-thumbnail-url").classList.add("is-danger");
    container.querySelector(".media-thumbnail-url-error").classList.remove("is-hidden");
    if (ev.target.currentSrc.trim() == "") {
      container.querySelector(".media-thumbnail-image").setAttribute("src", MissingImage);
    }
    console.log("Failed to load image:", ev);
  });

  container.querySelector(".media-thumbnail-image").addEventListener("load", (ev) => {
    container.querySelector(".media-thumbnail-url").classList.remove("is-danger");
    container.querySelector(".media-thumbnail-url-error").classList.add("is-hidden");
  });

  container.querySelector(".media-thumbnail-url").addEventListener("change", (ev) => {
    console.log("Updated media item thumbnail image URL to:", ev.target.value);
    mediaItem.setThumbnailUrl(ev.target.value);
    container.querySelector(".media-thumbnail-image").setAttribute("src", ev.target.value);
    onUpdated(mediaItem);
    refreshPlayer(container, mediaItem);
  });

  container.querySelector(".media-playback-url").addEventListener("change", (ev) => {
    console.log("Updated media item playback URL to:", ev.target.value);
    mediaItem.setPlaybackUrl(ev.target.value);
    onUpdated(mediaItem);
    refreshPlayer(container, mediaItem);
  });

  container.querySelector(".media-title").addEventListener("change", (ev) => {
    console.log("Updated media item title to:", ev.target.value);
    mediaItem.setName(ev.target.value);
    onUpdated(mediaItem);
  });

  container.querySelector(".media-description").addEventListener("change", (ev) => {
    console.log("Updated intro video description to:\n" + ev.target.value);
    mediaItem.setDescription(ev.target.value);
    onUpdated(mediaItem);
  });

  container.querySelector(".media-type").addEventListener("change", (ev) => {
    console.log("Updated media item type:", ev.target.value);
    mediaItem.setType(stringTypeToEnum(ev.target.value));
    if (mediaItem.getType() != MediaItem.MediaItemType.VIDEO && mediaItem.getType() != MediaItem.MediaItemType.AUDIO) {
      container.querySelector(".media-questions-group").classList.add("is-hidden");
    } else {
      container.querySelector(".media-questions-group").classList.remove("is-hidden");
    }
    refreshPlayer(container, mediaItem);
  });

  return container;
}

function refreshPlayer(container, mediaItem) {
  const placeholder = container.querySelector(".media-item-placeholder");
  const thumbnailImage = container.querySelector(".media-thumbnail-image");

  placeholder.removeAllChildren();
  if (mediaItem.getType() == MediaItem.MediaItemType.VIDEO && mediaItem.getPlaybackUrl().trim() != "") {
    thumbnailImage.classList.add("is-hidden");
    const playerElement = document.createElement("video-js");
    playerElement.classList.add("video-js", "vjs-default-skin", "vjs-big-play-centered");
    playerElement.setAttribute("controls", "controls");
    const sourceElement = document.createElement("source");
    if (process.env.NODE_ENV !== 'production') {
      console.log("Not in production, using dummy video");
      sourceElement.setAttribute("src", "http://playertest.longtailvideo.com/adaptive/bipbop/bipbop.m3u8");
    } else {
      sourceElement.setAttribute("src", mediaItem.getPlaybackUrl());
    }
    sourceElement.setAttribute("type", "application/x-mpegURL");
    playerElement.appendChild(sourceElement);
    placeholder.appendChild(playerElement);

    let posterURL = mediaItem.getThumbnailUrl();
    if (posterURL.trim() == "") {
      posterURL = MissingImage;
    }

    videojs(playerElement, {
      poster: posterURL,
      autoplay: false
    }, () => {
      console.log("Video is ready");
    });
  } else {
    thumbnailImage.classList.remove("is-hidden");
  }
}

function refreshViews(container, mediaItem, { isReadonly = false, isAdmin = false } = {}) {

  container.querySelector(".media-title").value = mediaItem.getName();
  container.querySelector(".media-description").value = mediaItem.getDescription();
  container.querySelector(".media-thumbnail-url").value = mediaItem.getThumbnailUrl();
  container.querySelector(".media-playback-url").value = mediaItem.getPlaybackUrl();
  container.querySelector(".media-thumbnail-image").setAttribute("src", mediaItem.getThumbnailUrl());
  container.querySelector(".media-type").value = enumToStringType(mediaItem.getType());

  if (mediaItem.getType() != MediaItem.MediaItemType.VIDEO && mediaItem.getType() != MediaItem.MediaItemType.AUDIO) {
    container.querySelector(".media-questions-group").classList.add("is-hidden");
  } else {
    container.querySelector(".media-questions-group").classList.remove("is-hidden");
  }

  const questionsContainer = container.querySelector(".media-questions-container");
  questionsContainer.removeAllChildren();
  mediaItem.getQuestionsList().forEach((question) => {
    const qTitle = document.createElement("p");
    qTitle.classList.add("has-text-weight-semibold");
    qTitle.innerText = question.getQuestion() + ` (${question.getTime()}s)`;
    if (!isReadonly && isAdmin) {
      qTitle.innerHTML += ` <a>${localize({en: "Edit", sv: "Redigera"})}</a>`
      qTitle.querySelector("a").addEventListener("click", () => {
        new MediaQuestionModal(container, {
          question: question,
          onSubmit: (question) => {
            refreshViews(container, mediaItem, { isReadonly: isReadonly, isAdmin: isAdmin });
          },
          isEditMode: true,
          onDelete: () => {
            if (!confirm(localize({en: "Are you sure you want to remove this question?", sv: "Är du säker på att du vill ta bort denna frågan?"}))) {
              return;
            }
            const items = [];
            mediaItem.getQuestionsList().forEach((oldQuestion) => {
              if (oldQuestion != question) {
                items.push(oldQuestion);
              }
            });
            mediaItem.setQuestionsList(items);
            refreshViews(container, mediaItem, { isReadonly: isReadonly, isAdmin: isAdmin });
          }
        }).show();
      });
    }
    questionsContainer.appendChild(qTitle);
    const ansContainer = document.createElement("div");
    ansContainer.classList.add("content");
    const list = document.createElement("ul");

    question.getAnswersList().forEach((answer) => {
      const ans = document.createElement("li");
      if (answer.getIsCorrect()) {
        ans.innerHTML = `${answer.getAnswer()} <span class="tag is-success">${localize({en: "Correct", sv: "Rätt"})}</span>`;
      } else {
        ans.innerHTML = `${answer.getAnswer()}`;
      }
      list.appendChild(ans);
    });

    ansContainer.appendChild(list);
    questionsContainer.appendChild(ansContainer);
  });

  refreshPlayer(container, mediaItem);
}


function stringTypeToEnum(stringType) {
  switch (stringType) {
    case "video":
      return MediaItem.MediaItemType.VIDEO;
    case "pdf":
      return MediaItem.MediaItemType.PDF;
    case "image":
      return MediaItem.MediaItemType.IMAGE;
    case "audio":
      return MediaItem.MediaItemType.AUDIO;
    case "web":
      return MediaItem.MediaItemType.WEB;
    default:
      throw "unknown media item type: " + stringType
  }
}

function enumToStringType(enumType) {
  switch (enumType) {
    case MediaItem.MediaItemType.VIDEO:
      return "video";
    case MediaItem.MediaItemType.PDF:
      return "pdf";
    case MediaItem.MediaItemType.IMAGE:
      return "image";
    case MediaItem.MediaItemType.AUDIO:
      return "audio";
    case MediaItem.MediaItemType.WEB:
      return "web";
    default:
      throw "unknown media item type: " + enumType
  }
}
