import template from './index.html';

import localize from 'localize';

import { Logging } from 'tummylab-protobuf/js/program.v1/program_config/task_config_pb';

const strings = {
  en: {
    log_specific: "Log a specific item",
    item_uuid_label: "Item UUID",
    min_count: "Minimum amount to log",
    min_duration: "Minimum duration to log",
    is_onboarding: "Is part of onboarding",
    category: {
      label: "Item category",
      help: "Choose which type of item to log",
      activity: "Activity",
      bowel: "Bowel movement",
      food: "Food or drink",
      medicine: "Medicine",
      sleep: "Sleep",
      supplement: "Supplement",
      symptom: "Symptom",
      period: "Period",
      generic: "Generic",
    }
  },
  sv: {
    log_specific: "Logga en specifik sak",
    item_uuid_label: "Sakens UUID",
    min_count: "Minsta mängd att logga",
    min_duration: "Minsta längd att logga",
    is_onboarding: "Är en del av onboarding",
    category: {
      label: "Kategori",
      help: "Välj vad det är som ska loggas",
      activity: "Aktivitet",
      bowel: "Toalettbesök",
      food: "Mat eller dryck",
      medicine: "Medicin",
      sleep: "Sömn",
      supplement: "Supplement",
      symptom: "Symtom",
      period: "Mens",
      generic: "Övrigt",
    }
  }
}

export default function Setup(config, { isReadonly = false, isAdmin = false } = {}) {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings),
    config: {
      item_uuid: config.getItemUuid(),
      min_count: config.getMinCount(),
      min_duration: config.getMinDuration(),
    }
  });

  if (isReadonly) {
    container.querySelectorAll("input").forEach((match) => {
      match.classList.add("is-static");
      match.setAttribute("readonly", "readonly");
      if (match.type == "checkbox") {
        match.setAttribute("disabled", "disabled");
      }
    });
    container.querySelectorAll("select").forEach((match) => {
      match.setAttribute("disabled", "disabled");
    });
  }

  function refreshVisibilities() {
    if (container.querySelector(".logging-specific").checked) {
      container.querySelector(".logging-item-uuid").parentElement.parentElement.classList.remove("is-hidden");
    } else {
      container.querySelector(".logging-item-uuid").parentElement.parentElement.classList.add("is-hidden");
    }
  }

  if (config.getHasItemUuid()) {
    container.querySelector(".logging-specific").setAttribute("checked", "checked");
  }
  if (config.getIsOnboardingTask()) {
    container.querySelector(".logging-onboarding").setAttribute("checked", "checked");
  }
  container.querySelector(".logging-config").value = enumToStringType(config.getCategory());
  container.querySelector(".logging-config").addEventListener("change", (ev) => {
    console.log("Setting logging category to", ev.target.value);
    config.setCategory(stringTypeToEnum(ev.target.value));
    refreshVisibilities();
  });

  container.querySelector(".logging-specific").addEventListener("change", (ev) => {
    console.log("Log specific", ev.target.checked);
    config.setHasItemUuid(ev.target.checked);
    refreshVisibilities();
  });

  container.querySelector(".logging-min-count").addEventListener("input", (ev) => {
    console.log("Changing min count to", ev.target.value);
    config.setMinCount(ev.target.value);
  });

  container.querySelector(".logging-min-duration").addEventListener("input", (ev) => {
    console.log("Changing min duration to", ev.target.value);
    config.setMinDuration(ev.target.value);
  });

  container.querySelector(".logging-item-uuid").addEventListener("input", (ev) => {
    console.log("Setting item UUID to", ev.target.value);
    config.setItemUuid(ev.target.value);
  });

  container.querySelector(".logging-onboarding").addEventListener("change", (ev) => {
    console.log("Setting is onboarding", ev.target.checked);
    config.setIsOnboardingTask(ev.target.checked);
  });

  if (!isAdmin) {
    container.querySelector(".logging-onboarding").parentElement.parentElement.remove();
  }


  refreshVisibilities();

  return container;
}


function stringTypeToEnum(stringType) {
  switch (stringType) {
    case "activity":
      return Logging.LoggingCategory.ACTIVITY;
    case "bowel":
      return Logging.LoggingCategory.BOWELMOVEMENT;
    case "food":
      return Logging.LoggingCategory.FOOD;
    case "medicine":
      return Logging.LoggingCategory.MEDICINE;
    case "sleep":
      return Logging.LoggingCategory.SLEEP;
    case "supplement":
      return Logging.LoggingCategory.SUPPLEMENT;
    case "symptom":
      return Logging.LoggingCategory.SYMPTOM;
    case "period":
      return Logging.LoggingCategory.PERIOD;
    case "generic":
      return Logging.LoggingCategory.GENERIC;
    default:
      throw "unknown logging item category: " + stringType
  }
}

function enumToStringType(enumType) {
  switch (enumType) {
    case Logging.LoggingCategory.ACTIVITY:
      return "activity";
    case Logging.LoggingCategory.BOWELMOVEMENT:
      return "bowel";
    case Logging.LoggingCategory.FOOD:
      return "food";
    case Logging.LoggingCategory.MEDICINE:
      return "medicine";
    case Logging.LoggingCategory.SLEEP:
      return "sleep";
    case Logging.LoggingCategory.SUPPLEMENT:
      return "supplement";
    case Logging.LoggingCategory.SYMPTOM:
      return "symptom";
    case Logging.LoggingCategory.PERIOD:
      return "period";
    case Logging.LoggingCategory.GENERIC:
      return "generic";
    default:
      throw "unknown logging category enum: " + enumType;
  }
}
