
import localize from 'localize';
import Sortable from 'sortablejs';

import { Modal } from './modal';

export class DaysOrderModal extends Modal {
  constructor(container, days, {
    onSubmit = (newDays) => { console.log("Submitted new days order", newDays) }
  } = {}) {
    super(container, {
      title: localize({en: "Change days' order", sv: "Ändra dagarnas ordning"}),
      positiveButton: localize({en: "Save new order", sv: "Spara ny ordning"}),
      neutralButton: localize({en: "Cancel", sv: "Avbryt"})
    });

    this.onSubmit = onSubmit;

    const body = document.createElement("div");

    days.forEach((day) => {
      const box = document.createElement("div");
      box.classList.add("box", "day-container");
      const name = document.createElement("p");
      name.classList.add("has-text-weight-semibold");
      name.innerText = day.getTitle();
      const uuid = document.createElement("div");
      uuid.classList.add("is-hidden", "uuid-container");
      uuid.innerText = day.getUuid();
      box.appendChild(name);
      box.appendChild(uuid);
      body.appendChild(box);
    })

    this.addContent(body);

    Sortable.create(body, {
      animation: 150,
    });


    this.onPositive = () => {
      const newDays = [];
      body.querySelectorAll(".day-container").forEach((match) => {
        const uuid = match.querySelector(".uuid-container").innerText;
        let foundDay = false;
        days.forEach((day) => {
          if (day.getUuid() == uuid) {
            newDays.push(day);
            foundDay = true;
          }
        });
        if (!foundDay) {
          throw "Lost track of day with uuid: " + uuid;
        }
      });
      this.onSubmit(newDays);
      this.destroy();
    }
  }
}