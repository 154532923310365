import template from './index.html';

import uuidv4 from 'uuid';
import { FormModal } from 'components/modal/form_modal';
import { getPrograms, getProgramLock, createProgram } from 'api/tmyConfigEditorV1';
import * as authToken from 'authToken';
import * as comp from 'comparator';
import { addOnNavigatedCallback } from 'app';
import { Program } from 'tummylab-protobuf/js/program.v1/program_config_pb';

import localize from 'localize';
import ErrorNotification from 'components/notification/error';
import ProgramSummary from 'components/program_summary';

const strings = {
  en: {
    title: "Programs",
    loading: "Loading programs",
    new_program: "Create new program",
  },
  sv: {
    title: "Program",
    loading: "Laddar program",
    new_program: "Skapa ett nytt program",
  }
};

const AUTO_REFRESH_INTERVAL = 2500; // ms

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings)
  });

  const progressBar = container.querySelector(".progress");
  const programListContainer = container.querySelector(".program-list");
  const createNewProgramButton = container.querySelector("#create-new-program")

  const isAdmin = authToken.isAdmin();
  if (!isAdmin) {
    // This page is for admins only
    container.querySelector(".container").appendChild(ErrorNotification(localize({
      en: "This page is for admins only",
      sv: "Denna sidan är endast för administratörer"
    })));
    progressBar.remove();
    return container;
  }

  function loadPrograms(showProgress = true) {
    if (showProgress) {
      progressBar.classList.remove("is-hidden");
    }

    getPrograms().then((programs) => {
      const locksForPrograms = {};
      const lockPromises = [];

      // Get all the locks
      programs.forEach((program) => {
        const p = getProgramLock(program.getUuid())
        .then((lock) => {
          locksForPrograms[program.getUuid()] = lock;
        })
        .catch((err) => {
          // This will happen if the program is not locked
          console.log("Could not get lock:", err)
          locksForPrograms[program.getUuid()] = null;
        })
        lockPromises.push(p);
      });

      // Wait until we have all locks, before we populate programs
      Promise.all(lockPromises).then(() => {
        programs = programs.sort(comp.ascending((program => program.getTitle())));
        console.log("Got", programs.length, "programs");
        // Remove any previously loaded programs
        programListContainer.removeAllChildren();

        function appendSummary(container, program) {
          const li = document.createElement("li");
          li.classList.add("margin-list-item");
          const lock = locksForPrograms[program.getUuid()];
          li.appendChild(ProgramSummary(program, lock));
          container.appendChild(li);
        }

        progressBar.classList.add("is-hidden");

        programs.forEach((program) => {
          appendSummary(programListContainer, program);
        });
      });
    });
  }

  createNewProgramButton.addEventListener("click", (ev) => {
    console.log("Create new program");
    createNewProgram(container, () => {
      loadPrograms(true);
    });
  });

  loadPrograms(true);

  const refreshInterval = setInterval(() => {
    loadPrograms(false);
  }, AUTO_REFRESH_INTERVAL);

  addOnNavigatedCallback((page) => {
    clearInterval(refreshInterval);
    return true;
  });

  return container;
}

function createNewProgram(container, onCreated = () => {}) {
  const fields = [
    {
      key: "title",
      label: localize({en: "Program title", sv: "Programmets rubrik"}),
      help: localize({en: "Give the program a title", sv: "Ge programmet en rubrik"}),
    },
    {
      key: "scope",
      label: localize({en: "Scope", sv: "Scope"}),
      help: localize({en: "The scope determines which courses belong to the program", sv: "Scopet bestämmer vilka kurser som tillhör programmet"}),
    },
    {
      key: "prefix",
      label: "Ordinal title prefix",
      prefill: "Vecka",
      help: localize({en: "The ordinal title, e.g. \"Vecka 1\" is shown together with a course", sv: "Ordningstiteln, ex. \"Vecka 1\" visas tillsammans med en kurs"}),
    },
  ];
  const newModal = new FormModal(container, fields, {
    title: localize({ en: "Create a new program", sv: "Skapa ett nytt program" }),
    description: localize({
      en: "Create a new program with a given scope.",
      sv: "Skapa ett nytt program med ett givet scope."
    }),
    positiveButton: localize({en: "Create new program", sv: "Skapa nytt program"}),
    neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
    onSubmit: (values) => {
      console.log("Create program with values:",  values)

      const newProgram = new Program();
      newProgram.setUuid(uuidv4());
      newProgram.setName(values["title"]);
      newProgram.setType(Program.ProgramType.MAIN);
      newProgram.setVersion(1);

      createProgram(values["scope"], newProgram, values["prefix"])
      .then(() => {
        newModal.destroy();
        onCreated();
      })
      .catch((err) => {
        console.log("Failed to create program", err);
        alert("Could not create new program: " + err.message);
      })
    }
  });
  newModal.show();
}