import template from './index.html';

import uuidv4 from 'uuid';
import { FormModal } from 'components/modal/form_modal';
import { getCourses, getCourseLock, createCourse } from 'api/tmyConfigEditorV1';
import * as authToken from 'authToken';
import * as comp from 'comparator';
import { addOnNavigatedCallback } from 'app';
import { Course } from 'tummylab-protobuf/js/program.v1/program_config_pb';

import localize from 'localize';
import CourseSummary from 'components/course_summary';

const strings = {
  en: {
    title: "Courses",
    loading: "Loading courses",
    deleted_courses: "Deleted courses",
    new_course: "Create new course",
  },
  sv: {
    title: "Kurser",
    loading: "Laddar kurser",
    deleted_courses: "Borttagna kurser",
    new_course: "Skapa en ny kurs",
  }
};

const AUTO_REFRESH_INTERVAL = 2500; // ms

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings)
  });
  const progressBar = container.querySelector(".progress");
  const courseListContainer = container.querySelector(".course-list");
  const deletedCourseListContainer = container.querySelector(".deleted-course-list");
  const deletedCoursesContainer = container.querySelector("#deleted-courses-container");
  const createNewCourseButton = container.querySelector("#create-new-course");

  const isAdmin = authToken.isAdmin();

  if (isAdmin) {
    deletedCoursesContainer.classList.remove("is-hidden");
    createNewCourseButton.classList.remove("is-hidden");
  } else {
    createNewCourseButton.remove();
  }

  function loadCourses(showProgress = true) {
    if (showProgress) {
      progressBar.classList.remove("is-hidden");
    }
    getCourses().then((courses) => {
      const locksForCourses = {};
      const lockPromises = [];

      // Get all the locks
      courses.forEach((course) => {
        const p = getCourseLock(course.getUuid())
        .then((lock) => {
          locksForCourses[course.getUuid()] = lock;
        })
        .catch((err) => {
          // This will happen if a course is not locked
          console.log("Could not get lock:", err);
          locksForCourses[course.getUuid()] = null;
        })
        lockPromises.push(p);
      })

      // Wait until we have all locks, before we populate courses
      Promise.all(lockPromises).then(() => {
        courses = courses.sort(comp.ascending(course => course.getTitle()));
        console.log("Got", courses.length, "courses");
        // Remove any previously loaded courses
        while (courseListContainer.lastChild) {
          courseListContainer.removeChild(courseListContainer.lastChild);
        }
        while (deletedCourseListContainer.lastChild) {
          deletedCourseListContainer.removeChild(deletedCourseListContainer.lastChild);
        }

        function appendSummary(container, course) {
          const li = document.createElement("li");
          li.classList.add("margin-list-item");
          const lock = locksForCourses[course.getUuid()];
          li.appendChild(CourseSummary(course, lock, isAdmin, loadCourses));
          container.appendChild(li);
        }

        const normalCourses = courses.filter(course => course.getState() != proto.API.TMY.ConfigEditor.V1.Course.State.DELETED);
        const deletedCourses = courses.filter(course => course.getState() == proto.API.TMY.ConfigEditor.V1.Course.State.DELETED);

        progressBar.classList.add("is-hidden");

        normalCourses.forEach((course) => {
          appendSummary(courseListContainer, course);
        });

        deletedCourses.forEach((course) => {
          appendSummary(deletedCourseListContainer, course);
        });
      })
    });
  }

  createNewCourseButton.addEventListener("click", (ev) => {
    console.log("Create new course");
    createNewCourse(container, () => {
      loadCourses(true);
    });
  });

  loadCourses(true);

  const refreshInterval = setInterval(() => {
    loadCourses(false);
  }, AUTO_REFRESH_INTERVAL);

  addOnNavigatedCallback((page) => {
    clearInterval(refreshInterval);
    return true;
  });

  return container;
}

function createNewCourse(container, onCreated = () => {}) {
  const fields = [
    {
      key: "title",
      label: localize({en: "Course title", sv: "Kursens rubrik"}),
      help: localize({en: "Give the course a title", sv: "Ge kursen en rubrik"}),
    },
    {
      key: "scope",
      label: localize({en: "Scope", sv: "Scope"}),
      help: localize({en: "The scope determines who can see the course", sv: "Scopet bestämmer vem som kommer att kunna se kursen"}),
    },
  ];
  const newModal = new FormModal(container, fields, {
    title: localize({ en: "Create a new course", sv: "Skapa en ny kurs" }),
    description: localize({
      en: "Create a new course with a given scope. As soon as it has been created it will be visible for anyone with access to the given scope.",
      sv: "Skapa en ny kurs men ett givet scope. Så fort kursen har skapat så kommer den att vara synlig för alla med tillgång till det scopet.",
    }),
    positiveButton: localize({en: "Create new course", sv: "Skapa ny kurs"}),
    neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
    onSubmit: (values) => {
      console.log("Create course with values:",  values)

      const newCourse = new Course();
      newCourse.setUuid(uuidv4());
      newCourse.setTitle(values["title"]);

      createCourse(values["scope"], newCourse)
      .then(() => {
        newModal.destroy();
        onCreated();
      })
      .catch((err) => {
        alert("Could not create new course: " + err.message);
      })
    }
  });
  newModal.show();
}
