const env = (function(host) {
  if (host.indexOf("localhost") !== -1) {
    return 'localhost';
  } else if (host.indexOf("staging") !== -1) {
    return 'staging';
  } else {
    return 'production';
  }
})(document.location.host);

export const baseURL = {
  localhost:  "http://localhost:8080",
  staging:    "https://api.staging.backend.curotech.net",
  production: "https://api.production.backend.curotech.net",
}[env];

export const loginPlatformToken = {
  localhost:  "tmy-config-editor",
  staging:    "tmy-config-editor",
  production: "tmy-config-editor"
}[env];

export const language = {
  localhost: Math.random() > 0.5 ? "en" : "sv",
  staging: "sv",
  production: "sv"
}[env];
