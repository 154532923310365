import template from './index.html';
import dragItem from './drag_item.html';
import externalResourceItem from './external_resource_item.html';
import dailyReviewQuestionItem from './daily_review_question.html';
import localize from 'localize';
import uuidv4 from 'uuid';
import { addOnNavigatedCallback } from 'app';
import MissingImage from '../../../img/image-missing.png';

import { getCourse, patchCourse, putCourseResources, patchCourseLock, deleteCourseLock } from 'api/tmyConfigEditorV1';
import * as authToken from 'authToken';

import ErrorNotification from 'components/notification/error';
import { FormModal } from 'components/modal/form_modal';
import { DaysOrderModal } from 'components/modal/days_order_modal';
import EditMediaItem from 'components/edit_media_item/';
import EditDay from 'components/edit_day/';
import { navigateTo } from '../../app';
import Sortable from 'sortablejs';

import { Course as EditableCourse }from 'tummylab-protobuf/js/api.tmy.config_editor.v1/course_pb';
import { Course, Day } from 'tummylab-protobuf/js/program.v1/program_config_pb';
import { MediaItem } from 'tummylab-protobuf/js/program.v1/media_item_pb';

const DEFAULT_WHAT_WILL_LEARN_TITLE = "DENNA VECKA KOMMER DU ATT";
const DEFAULT_WHAT_DID_LEARN_TITLE = "DENNA VECKA HAR DU";

const strings = {
  en: {
    cancel: "Cancel",
    save_course: "Save changes",
    preview: "Preview",
    add: "Add",
    deleted: "Deleted",
    last_edited: "Last edited",
    course: {
      tooltip: "The following part will be shown before the patient starts with day 1 of your week. It's your task to provide the patient with initial information about your week.",
      title: "Title",
      title_tooltip: "Write down the title of your week.",
      ordinal_title: "Ordinal Title",
      image_url: "Course Image",
      description: "Description",
      description_tooltip: "Describe what the patient will be learning this week. What main points will you talk about and what the patient should focus on.",
      external_resources: "External resources",
      add_external_resource: "Add external resource",
    },
    invalid_url: "Invalid URL",
    will_learn: {
      tooltip: "Write down the main learning points the patient will be learning this week.",
      header: "Start of week learning points",
      title: "Title",
      bullets: "Bullet points"
    },
    did_learn: {
      tooltip: "Write down the main learning points that the patient has learned at the end of your week.",
      header: "End of week learning points",
      title: "Title",
      bullets: "Bullet points"
    },
    daily_review: {
      tooltip: `At the end of each day, the patient will review his/her day by answering three questions.

      Your task is to decide on which three questions will be answered at the end of each day of the week. You will also have to decide on the labels on the left and right of the scale. (e.g. Inget-Mycket, Dåligt-Mycket bra).

      Remember that patients will receive the same question each day of your week, so choose something that could yield interesting results over 7 days of data.`,
      header: "Daily review questions",
      add_new: "Add new question",
    },
    intro_video: {
      tooltip: "During your week's introduction the patient will be able to see your introductory video. You don't have to do anything here.",
      header: "Intro video",
      add_new: "Add video",
      remove: "Delete video",
      no_video: "There is currently no video in this course",
    },
    extra_resources: {
      tooltip: `During your week you have the opportunity to include extra material (e.g. a video of exercises or a PDF with important information).

      If you are interested in adding extra material, call or send a mail to us! We will get it set up for you.`,
      header: "Extra resources",
      empty: "There are no extra resources in this course. You can add some.",
      add_new: "Add extra resource",
    },
    days: {
      tooltip: "Part two of your program week contains the 7 days of your week. Each day needs a title, description of each day and tasks that patients have to complete.",
      header: "Course days",
      add_new: "Add new day",
      change_order: "Change day order",
      empty: "There are no days added to this course. You can add some.",
    }
  },
  sv: {
    cancel: "Avbryt",
    save_course: "Spara ändringar",
    preview: "Visa",
    add: "Lägg till",
    deleted: "Borttagen",
    last_edited: "Senast redigerad",
    course: {
      tooltip: "Den följande informationen kommer att visas innan patienten startar dag 1 av din vecka. Det är din uppgift att ge patienten introducerande information om din vecka.",
      title: "Rubrik",
      title_tooltip: "Skriv en rubrik som passar till din vecka.",
      ordinal_title: "Ordningstitel",
      image_url: "Kursbild",
      description: "Beskrivning",
      description_tooltip: "Beskriv vad patienten kommer att lära sig denna veckan. Vad kommer du främst att tala om och vad borde patienten fokusera på.",
      external_resources: "Externa resurser",
      add_external_resource: "Lägg till extern resurs",
    },
    invalid_url: "Felaktig länk",
    will_learn: {
      tooltip: "Skriv ner några huvudsakliga inlärningspunkter som patienten kommer att lära sig denna veckan.",
      header: "Inlärningspunker vid veckans start",
      title: "Titel",
      bullets: "Punktlista"
    },
    did_learn: {
      tooltip: "Skriv ner dem huvudsakliga inlärningspunkterna som patienten kommer att ha lärt sig vid slutet av din vecka.",
      header: "Inlärningspunker vid veckans slut",
      title: "Titel",
      bullets: "Punktlista"
    },
    daily_review: {
      tooltip: `Vid slutet av varje dag kommer patienten att få utvärdera sin dag genom att svara på tre frågor.

      Din uppgift är att bestämma vilka tre frågor som ska besvaras i slutet av varje dag i veckan. Du behöver också bestämma vilka etiketter som ska visas vid höver och vänster av skalan (t.ex. Inget–Mycket, Dåligt—Mycket bra).

      Kom ihåg att patienten kommer få samma frågor varje dag av din vecka, så välj något som skulle kunna ge intressanta reslutat över 7 dagars insamlad data.`,
      header: "Daglig utvärdering",
      add_new: "Skapa ny fråga",
    },
    intro_video: {
      tooltip: "Under introduktionen till din vecka så kommer patienten att kunna se på din introduktionsvideo. Här behöver du inte ändra någonting.",
      header: "Introduktionsvideo",
      add_new: "Lägg till video",
      remove: "Ta bort video",
      no_video: "Det finns ingen video i denna kursen just nu",
    },
    extra_resources: {
      tooltip: `Du har möjlighet att inkludera extramaterial i din vecka, exempelvis en video med övningar eller en PDF med viktig information.

      Om du är intresserad av att lägga till extramaterial, ring eller maila oss! Vi hjälper dig att lägga till det.`,
      header: "Extraresurser",
      empty: "Det finns inga extraresurser i den här kursen. Du kan lägga till några.",
      add_new: "Lägg till extraresurs",
    },
    days: {
      tooltip: "Nästa del av din programvecka består av dem 7 dagar som ingår i veckan. Varje dag behöver en rubrik, beskrivning av dagen och uppgifter som patienter ska slutföra.",
      header: "Dagar i kursen",
      add_new: "Lägg till ny dag",
      change_order: "Ändra dagarnas ordning",
      empty: "Det finns inga dagar i den här kursen. Du kan lägga till några.",
    }
  }
};

export default function Setup({isReadonly = false} = {}) {
  const isAdmin = authToken.isAdmin();
  const container = document.createElement("div");
  const courseUUID = require('querystring').parse(window.location.search.substring(1)).course_uuid || null;
  if (courseUUID === null) {
    container.appendChild(ErrorNotification(localize({ en: "No course specified", sv: "Ingen kurs angiven"}), false));
    return container;
  }

  let title = localize({en: "Edit Course", sv: "Redigera Kurs"});
  if (isReadonly) {
    title = localize({en: "View Course", sv: "Visa Kurs"});
  }
  container.innerHTML = template({
    t: localize(strings),
    title: title,
  });

  container.querySelectorAll(".tooltip-text").forEach((match) => {
    match.innerText = match.innerText.trim();
  });

  const progressBar = container.querySelector(".progress");

  getCourse(courseUUID)
  .then((course) => {
    populateCourse(container, course, { isReadonly: isReadonly, isAdmin: isAdmin });
  })
  .catch((err) => {
    const errText = localize({ en: "Failed to load course: ", sv: "Kunde inte ladda in kursen: "}) + err
    container.replaceChildren(ErrorNotification(errText, false));
  })
  .finally(() => progressBar.classList.add("is-hidden"));

  return container;
}

function populateCourse(container, course, { isReadonly = false, isAdmin = false } = {}) {
  const config = course.getConfig();
  let didChange = false;

  // This makes sure that if the user navigates away, we don't hold the course lock
  if (!isReadonly) {
    addOnNavigatedCallback((page) => {
      if (!didChange || (didChange && confirm(localize({en: "You have unsaved changes, these will be discarded.", sv: "Du har ändringar som inte sparats. Dessa kommer kastas bort."})))) {
        deleteCourseLock(course.getUuid())
          .catch((err) => console.log("Could not delete course lock:", err))
          .finally(() => navigateTo(page))
        return true;
      } else {
        return false;
      }
    });

    window.addEventListener("beforeunload", (ev) => {
      console.log("Unloading page");
      deleteCourseLock(course.getUuid())
        .catch((err) => console.log("Could not delete course lock:", err))
    });
  }

  if (isReadonly) {
    container.querySelector("#navbar-bottom").remove();
    container.querySelector(".edit-save").parentElement.remove();

    container.querySelectorAll("input").forEach((match) => {
      match.classList.add("is-static");
      match.setAttribute("readonly", "readonly")
    });

    container.querySelectorAll(".hidden-in-readonly").forEach((match) => {
      match.classList.add("is-hidden");
    });

    container.querySelectorAll("textarea").forEach((match) => {
      match.classList.add("is-static");
      match.style.borderStyle = "none";
      match.setAttribute("readonly", "readonly");
    });
  } else if (isAdmin) {
    container.querySelectorAll(".admin-only").forEach((match) => {
      match.classList.remove("is-hidden");
    });
    container.querySelectorAll(".normal-only").forEach((match) => {
      match.classList.add("is-hidden");
    });
  }

  container.querySelectorAll(".edit-cancel").forEach((match) => match.addEventListener("click", (ev) => {
    navigateTo("courses");
  }));

  let saveStatusTimer;
  container.querySelectorAll(".edit-save").forEach((match) => match.addEventListener("click", (ev) => {
    container.querySelectorAll(".edit-save").forEach((match) => match.classList.add("is-loading"));
    const patchCoursePromise = patchCourse(course.getUuid(), config);
    if (isAdmin) {
      console.log(course.getExternalResourcesList());
      patchCoursePromise.then(() => {
        return putCourseResources(course.getUuid(), course.getExternalResourcesList());
      });
    }
    patchCoursePromise.then(() => {
      console.log("Course saved")
      didChange = false;
      container.querySelector(".errors-container").removeAllChildren();
      container.querySelector("#last-edited-text").innerText =  (new Date()).toISODateStringWithTime();
      container.querySelectorAll(".edit-status").forEach((match) => {
        const statusText = localize({
          en: "Successfully saved at ",
          sv: "Sparades korrekt kl. "
        });
        match.innerText = statusText + (new Date).toTimeString();
        if (saveStatusTimer !== undefined) {
          clearTimeout(saveStatusTimer);
        }
        saveStatusTimer = setTimeout(() => {
          container.querySelectorAll(".edit-status").forEach((match) => { match.innerText = "" });
        }, 1000 * 60 * 30 /* After 30 minutes, clear the status text */);
      });
    })
    .catch((err) => {
      console.log("Failed to save course:", err)
      const errMsg = localize({en: "The course could not be saved!", sv: "Kursen kunde inte sparas!"}) + "\n\n" + err.message
      container.querySelector(".errors-container").appendChild(ErrorNotification(errMsg));
    })
    .finally(() => container.querySelectorAll(".edit-save").forEach((match) => match.classList.remove("is-loading")))
  }));

  // BASIC INFORMATION
  container.querySelector("#last-edited-text").innerText =  (new Date(course.getLastEditedAt() * 1000)).toISODateStringWithTime();
  if (isCourseFinalized(course)) {
    container.querySelector("#icon-finalized").parentElement.classList.remove("is-hidden");
  }
  if (isCourseDeleted(course)) {
    container.querySelector("#is-deleted-tag").parentElement.classList.remove("is-hidden");
  }

  container.querySelector("#course-title").value = config.getTitle();
  container.querySelector("#course-title").addEventListener("change", (ev) => {
    console.log("Updated title to:", ev.target.value);
    config.setTitle(ev.target.value);
    didChange = true;
    refreshLock(course.getUuid());
  });

  container.querySelector("#course-image-url").value = config.getImageUrl();
  container.querySelector("#course-preview-image").setAttribute("src", config.getImageUrl());
  container.querySelector("#course-preview-image").addEventListener("error", (ev) => {
    container.querySelector("#course-image-url").classList.add("is-danger");
    container.querySelector("#course-image-url-error").classList.remove("is-hidden");
    if (ev.target.currentSrc.trim() == "") {
      container.querySelector("#course-preview-image").setAttribute("src", MissingImage);
    }
    console.log("Failed to load image:", ev);
  });
  container.querySelector("#course-preview-image").addEventListener("load", (ev) => {
    container.querySelector("#course-image-url").classList.remove("is-danger");
    container.querySelector("#course-image-url-error").classList.add("is-hidden");
  });
  container.querySelector("#course-image-url").addEventListener("change", (ev) => {
    console.log("Updated image URL to:", ev.target.value);
    config.setImageUrl(ev.target.value);
    container.querySelector("#course-preview-image").setAttribute("src", ev.target.value);
    didChange = true;
    refreshLock(course.getUuid());
  });

  container.querySelector("#course-description").value = config.getDescription();
  container.querySelector("#course-description").addEventListener("change", (ev) => {
    console.log("Updated description to:\n" + ev.target.value);
    config.setDescription(ev.target.value);
    didChange = true;
    refreshLock(course.getUuid());
  });

  if (course.getExternalResourcesList().length > 0) {
    const externalList = container.querySelector("#external-resources-list");
    function addExternalResourceToList(resource) {
      const li = document.createElement("li");
      li.classList.add("draggable-li");
      const content = document.createElement("div");
      content.innerHTML = externalResourceItem({
        title: resource.getTitle(),
        resource_url: resource.getResourceUrl(),
      });
      if (isAdmin && !isReadonly) {
        content.querySelectorAll(".admin-only").forEach((match) => {
          match.classList.remove("is-hidden");
        });
        content.querySelector(".icon-button").addEventListener("click", (ev) => {
          const fields = [
            {
              key: "title",
              label: localize({en: "Title", sv: "Titel"}),
              prefill: resource.getTitle(),
            },
            {
              key: "url",
              label: localize({en: "Resource URL", sv: "Resurslänk"}),
              prefill: resource.getResourceUrl(),
            }
          ];
          const newModal = new FormModal(container, fields, {
            title: localize({en: "Edit external resource", sv: "Redigera extern resurs"}),
            description: localize({en: "Create an external resource that editors can see.", sv: "Skapa en extern resurs som kliniker kan se."}),
            positiveButton: localize({en: "Save", sv: "Spara"}),
            negativeButton: localize({en: "Delete", sv: "Ta bort"}),
            neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
            onSubmit: (values) => {
              resource.setTitle(values["title"]);
              resource.setResourceUrl(values["url"]);
              externalList.removeAllChildren();
              course.getExternalResourcesList().forEach((res) => {
                addExternalResourceToList(res);
              });
              didChange = true;
              refreshLock(course.getUuid());
            },
            onNegative: () => {
              if (!confirm(localize({en: "Are you sure you want to remove external resource?", sv: "Är du säker på att du vill ta bort den här externa resursen?"}))) {
                return;
              }
              const items = [];
              course.getExternalResourcesList().forEach((res) => {
                if (res != resource) {
                  items.push(res);
                }
              });
              course.setExternalResourcesList(items);
              externalList.removeAllChildren();
              course.getExternalResourcesList().forEach((res) => {
                addExternalResourceToList(res);
              });
              didChange = true;
              refreshLock(course.getUuid());
            }
          });
          newModal.show();
        });
      }
      li.appendChild(content);
      externalList.appendChild(li);
    }
    course.getExternalResourcesList().forEach((resource) => {
      addExternalResourceToList(resource);
    });
    if (isAdmin) {
      container.querySelector("#add-external-resource").addEventListener("click", (ev) => {
        const fields = [
          {
            key: "title",
            label: localize({en: "Title", sv: "Titel"}),
          },
          {
            key: "url",
            label: localize({en: "Resource URL", sv: "Resurslänk"}),
          }
        ];
        const newModal = new FormModal(container, fields, {
          title: localize({en: "Create external resource", sv: "Skapa extern resurs"}),
          description: localize({en: "Create an external resource that editors can see.", sv: "Skapa en extern resurs som kliniker kan se."}),
          positiveButton: localize({en: "Save", sv: "Spara"}),
          neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
          onSubmit: (values) => {
            const newExternalResource = new EditableCourse.ExternalResource();
            newExternalResource.setTitle(values["title"]);
            newExternalResource.setResourceUrl(values["url"]);
            course.addExternalResources(newExternalResource);
            addExternalResourceToList(newExternalResource);
            didChange = true;
            refreshLock(course.getUuid());
          }
        });
        newModal.show();
      });
      Sortable.create(externalList, {
        animation: 150,
        onEnd: () => {
          const items = [];
          for (let i = 0; i < externalList.children.length; i++) {
            const anchor = externalList.children[i].querySelector(".dragged-text");
            const resource = new EditableCourse.ExternalResource();
            resource.setTitle(anchor.innerText);
            resource.setResourceUrl(anchor.getAttribute("href"));
            items.push(resource);
          }
          course.setExternalResourcesList(items);
          didChange = true;
          refreshLock(course.getUuid());
        },
      });
    }

  } else if (!isAdmin) {
    container.querySelector("#external-resources-field").remove();
  }


  // WHAT WILL YOU LEARN
  const whatWillLearnTitle = container.querySelector("#what-will-learn-title");
  if (isReadonly) {
    whatWillLearnTitle.parentElement.parentElement.classList.remove("is-hidden");
    whatWillLearnTitle.classList.add("is-static", "has-text-weight-bold");
    whatWillLearnTitle.setAttribute("readonly", "readonly");
  }
  const whatWillLearnTitleDropdown = container.querySelector("#what-will-learn-title-dropdown");
  if (config.getWhatYouWillLearnTitle().trim() == "") {
    config.setWhatYouWillLearnTitle(DEFAULT_WHAT_WILL_LEARN_TITLE);
  }
  whatWillLearnTitle.value = config.getWhatYouWillLearnTitle();
  container.querySelector("#what-will-learn-current").innerText = config.getWhatYouWillLearnTitle();
  container.querySelector("#what-will-learn-current").value = config.getWhatYouWillLearnTitle();

  // Make sure we don't have duplicates in the dropdown
  for (let i = 1; i < whatWillLearnTitleDropdown.children.length; i++) {
    if (whatWillLearnTitleDropdown.children[i].value == config.getWhatYouWillLearnTitle()) {
      whatWillLearnTitleDropdown.children[i].remove();
      break;
    }
  }

  whatWillLearnTitleDropdown.addEventListener("change", (ev) => {
    console.log("Change what will learn title", ev.target.value);
    config.setWhatYouWillLearnTitle(ev.target.value);
    didChange = true;
    refreshLock(course.getUuid());
  });

  if (isAdmin) {
    whatWillLearnTitle.addEventListener("input", (ev) => {
      config.setWhatYouWillLearnTitle(ev.target.value);
    });
  }

  const willLearnList = container.querySelector("#what-will-learn-list");

  function addToList(list, text, afterDelete) {
    const li = document.createElement("li");
    li.classList.add("draggable-li");
    const content = document.createElement("div");
    content.innerHTML = dragItem({text: text});
    const textView = content.querySelector(".dragged-text");
    const deleteButton = content.querySelector(".delete-button");
    if (isAdmin) {
      deleteButton.addEventListener("click", () => {
        console.log("Delete list item:", text);
        list.removeChild(li);
        didChange = true;
        refreshLock(course.getUuid());
        afterDelete();
      });
      deleteButton.classList.remove("is-hidden");
    } else {
      deleteButton.remove();
    }
    content.querySelector(".edit-button").addEventListener("click", () => {
      const fields = [
        {
          key: "bullet",
          label: localize({en: "Bullet point", sv: "Punkt"}),
          prefill: textView.innerText
        }
      ]
      const editModal = new FormModal(container, fields, {
        title: localize({en: "Edit bullet point", sv: "Redigera punkt"}),
        description: localize({en: "Edit bullet point text", sv: "Redigera punktens text"}),
        positiveButton: localize({en: "Save", sv: "Spara"}),
        neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
        onSubmit: (values) => {
          textView.innerText = values["bullet"];
          didChange = true;
          refreshLock(course.getUuid());
        }
      });
      editModal.show();
    });
    li.appendChild(content);
    list.appendChild(li);
  }

  function getItemsFromList(list) {
    const result = [];
    for (let i = 0; i < list.children.length; i++) {
      const child = list.children[i];
      const childText = child.querySelector(".dragged-text").innerText;
      result.push(childText);
    }
    return result;
  }

  config.getWhatYouWillLearnList().forEach((item) => {
    addToList(willLearnList, item, () => {
      config.setWhatYouWillLearnList(getItemsFromList(willLearnList));
    });
  })

  const whatWillLearnAddButton = container.querySelector("#what-will-learn-add-button");
  whatWillLearnAddButton.addEventListener("click", (ev) => {
    const inputField = container.querySelector("#what-will-learn-add-text");
    const itemToAdd = inputField.value;
    inputField.value = "";
    addToList(willLearnList, itemToAdd, () => {
      config.setWhatYouWillLearnList(getItemsFromList(willLearnList));
    });
    config.setWhatYouWillLearnList(getItemsFromList(willLearnList));
    didChange = true;
    refreshLock(course.getUuid());
  });
  if (isReadonly) {
    whatWillLearnAddButton.parentElement.remove();
    willLearnList.querySelectorAll(".fa-arrows-alt").forEach((match) => {
      match.classList.remove("fa-arrows-alt");
      match.classList.add("fa-check");
      match.parentElement.classList.remove("drag-handle");
  });
    willLearnList.querySelectorAll("a").forEach((match) => { match.remove(); });
  }

  if (!isReadonly) {
    Sortable.create(willLearnList, {
      handle: '.drag-handle',
      animation: 150,
      onEnd: () => {
        didChange = true;
        refreshLock(course.getUuid());
        config.setWhatYouWillLearnList(getItemsFromList(willLearnList));
      }
    });
  }

  // WHAT DID YOU LEARN
  const whatDidLearnTitle = container.querySelector("#what-did-learn-title");
  if (isReadonly) {
    whatDidLearnTitle.parentElement.parentElement.classList.remove("is-hidden");
    whatDidLearnTitle.classList.add("is-static", "has-text-weight-bold");
    whatDidLearnTitle.setAttribute("readonly", "readonly");
  }
  const whatDidLearnTitleDropdown = container.querySelector("#what-did-learn-title-dropdown");
  if (config.getWhatYouDidLearnTitle().trim() == "") {
    config.setWhatYouDidLearnTitle(DEFAULT_WHAT_DID_LEARN_TITLE);
  }
  whatDidLearnTitle.value = config.getWhatYouDidLearnTitle();
  container.querySelector("#what-did-learn-current").innerText = config.getWhatYouDidLearnTitle();
  container.querySelector("#what-did-learn-current").value = config.getWhatYouDidLearnTitle();

  // Make sure we don't have duplicates in the dropdown
  for (let i = 1; i < whatDidLearnTitleDropdown.children.length; i++) {
    if (whatDidLearnTitleDropdown.children[i].value == config.getWhatYouDidLearnTitle()) {
      whatDidLearnTitleDropdown.children[i].remove();
      break;
    }
  }

  whatDidLearnTitleDropdown.addEventListener("change", (ev) => {
    console.log("Change what did learn title", ev.target.value);
    config.setWhatYouDidLearnTitle(ev.target.value);
    didChange = true;
    refreshLock(course.getUuid());
  });

  if (isAdmin) {
    whatDidLearnTitle.addEventListener("input", (ev) => {
      config.setWhatYouDidLearnTitle(ev.target.value);
    });
  }

  const didLearnList = container.querySelector("#what-did-learn-list");

  config.getWhatYouDidLearnList().forEach((item) => {
    addToList(didLearnList, item, () => {
      config.setWhatYouDidLearnList(getItemsFromList(didLearnList));
    });
  })

  const whatDidLearnAddButton = container.querySelector("#what-did-learn-add-button");
  whatDidLearnAddButton.addEventListener("click", (ev) => {
    const inputField = container.querySelector("#what-did-learn-add-text");
    const itemToAdd = inputField.value;
    inputField.value = "";
    addToList(didLearnList, itemToAdd, () => {
      config.setWhatYouDidLearnList(getItemsFromList(didLearnList));
    });
    config.setWhatYouDidLearnList(getItemsFromList(didLearnList));
    didChange = true;
    refreshLock(course.getUuid());
  });
  if (isReadonly) {
    whatDidLearnAddButton.parentElement.remove();
    didLearnList.querySelectorAll(".fa-arrows-alt").forEach((match) => {
      match.classList.remove("fa-arrows-alt");
      match.classList.add("fa-check");
      match.parentElement.classList.remove("drag-handle");
    });
    didLearnList.querySelectorAll("a").forEach((match) => { match.remove(); });
  }

  if (!isReadonly) {
    Sortable.create(didLearnList, {
      handle: '.drag-handle',
      animation: 150,
      onEnd: () => {
        didChange = true;
        refreshLock(course.getUuid());
        config.setWhatYouDidLearnList(getItemsFromList(didLearnList));
      }
    });
  }

  // DAILY REVIEW QUESTIONS

  const dailyReviewQuestionsContainer = container.querySelector("#daily-review-questions-container");
  function refreshDailyReviewQuestions() {
    dailyReviewQuestionsContainer.removeAllChildren();
    config.getDailyReviewQuestionsList().forEach((question) => {
      addDailyReviewQuestion(question);
    });
  }
  refreshDailyReviewQuestions();

  function addDailyReviewQuestion(question) {
    const div = document.createElement("div");
    div.classList.add("column", "is-4", "daily-review-question");
    const box = document.createElement("div");
    box.classList.add("box");
    box.innerHTML = dailyReviewQuestionItem({
      uuid: question.getUuid(),
      question: question.getQuestion(),
      low: question.getMinLabel(),
      high: question.getMaxLabel(),
    });
    box.querySelector(".button").addEventListener("click", (ev) => {
      const fields = [
        {
          key: "question",
          label: localize({en: "Question", sv: "Fråga"}),
          prefill: question.getQuestion()
        },
        {
          key: "low",
          label: localize({en: "Label for negative value", sv: "Etikett för negativt värde"}),
          prefill: question.getMinLabel()
        }, {
          key: "high",
          label: localize({en: "Label for positive value", sv: "Etikett för positivt värde"}),
          prefill: question.getMaxLabel()
        }
      ];

      let negativeButtonText = localize({en: "Delete question", sv: "Ta bort fråga"});
      if (!isAdmin) {
        negativeButtonText = "";
      }
      const editModal = new FormModal(container, fields, {
        title: localize({ en: "Edit question", sv: "Redigera fråga" }),
        description: localize({
          en: "Here you can edit an existing daily review question.",
          sv: "Här kan du redigera en befintlig fråga.",
        }),
        positiveButton: localize({en: "Save changes", sv: "Spara ändringar"}),
        negativeButton: negativeButtonText,
        neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
        onNegative: () => {
          if (!isAdmin) {
            return;
          }
          let newQuestions = [];
          config.getDailyReviewQuestionsList().forEach((q) => {
            if (q.getUuid() != question.getUuid()) {
              newQuestions.push(q);
            }
          });
          config.clearDailyReviewQuestionsList();
          config.setDailyReviewQuestionsList(newQuestions);
          refreshDailyReviewQuestions();
        },
        onSubmit: (values) => {
          didChange = true;
          refreshLock(course.getUuid());
          question.setQuestion(values.question);
          question.setMinLabel(values.low);
          question.setMaxLabel(values.high);
          refreshDailyReviewQuestions();
        }
      });
      editModal.show();
    });
    if (isReadonly) {
      box.querySelector(".button").remove();
    }
    div.appendChild(box);
    dailyReviewQuestionsContainer.appendChild(div);
  }

  if (!isReadonly) {
    Sortable.create(dailyReviewQuestionsContainer, {
      animation: 150,
      onEnd: (ev) => {
        didChange = true;
        refreshLock(course.getUuid());
        let questions = [];
        ev.to.querySelectorAll(".daily-review-question").forEach((item) => {
          const uuid = item.querySelector(".question-uuid").innerText;
          const question = item.querySelector(".question-text").innerText;
          const lowLabel = item.querySelector(".question-low").innerText;
          const highLabel = item.querySelector(".question-high").innerText;
          questions.push(createNewDailyReviewQustion(question, lowLabel, highLabel, { uuid: uuid }));
        })
        config.clearDailyReviewQuestionsList();
        config.setDailyReviewQuestionsList(questions);
      }
    });
  }

  container.querySelector("#daily-review-add-button").addEventListener("click", (ev) => {
    console.log("Creating new daily review question");
    const fields = [
      {
        key: "question",
        label: localize({en: "Question", sv: "Fråga"}),
      },
      {
        key: "low",
        label: localize({en: "Label for negative value", sv: "Etikett för negativt värde"}),
      }, {
        key: "high",
        label: localize({en: "Label for positive value", sv: "Etikett för positivt värde"}),
      }
    ];
    const newModal = new FormModal(container, fields, {
      title: localize({ en: "Create a new question", sv: "Skapa en ny fråga" }),
      description: localize({
        en: "Here you can create a new daily review question.",
        sv: "Här kan du skapa en ny fråga till den dagliga utvärderingen.",
      }),
      positiveButton: localize({en: "Save question", sv: "Spara fråga"}),
      neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
      onSubmit: (values) => {
        didChange = true;
        refreshLock(course.getUuid());
        const newQuestion = createNewDailyReviewQustion(values.question, values.low, values.high);
        config.addDailyReviewQuestions(newQuestion);
        refreshDailyReviewQuestions();
      }
    });
    newModal.show();
  });
  if (isReadonly) {
    container.querySelector("#daily-review-add-button").parentElement.remove();
  }


  // INTRO VIDEO
  function refreshIntroVideoViews() {
    if (config.getIntroVideo() === undefined || config.getIntroVideo() == null) {
      container.querySelector("#intro-video-placeholder").classList.remove("is-hidden");
      container.querySelector("#intro-video-container").classList.add("is-hidden");
      container.querySelector("#intro-video-add-button").parentElement.classList.remove("is-hidden");
      container.querySelector("#intro-video-remove-button").parentElement.classList.add("is-hidden");
    } else {
      container.querySelector("#intro-video-placeholder").classList.add("is-hidden");
      container.querySelector("#intro-video-container").classList.remove("is-hidden");
      container.querySelector("#intro-video-add-button").parentElement.classList.add("is-hidden");
      container.querySelector("#intro-video-remove-button").parentElement.classList.remove("is-hidden");
      const introVideoView = EditMediaItem({
        mediaItem: config.getIntroVideo(),
        isReadonly: isReadonly,
        isAdmin: isAdmin,
      });
      container.querySelector(".intro-video-container-content").removeAllChildren();
      container.querySelector(".intro-video-container-content").appendChild(introVideoView);
    }
  }
  refreshIntroVideoViews();

  container.querySelector("#intro-video-add-button").addEventListener("click", () => {
    console.log("Create new intro video");
    const video = new MediaItem();
    video.setUuid(uuidv4());
    video.setType(MediaItem.MediaItemType.VIDEO);
    config.setIntroVideo(video);
    refreshIntroVideoViews();
  });
  if (isReadonly || !isAdmin) {
    container.querySelector("#intro-video-add-button").parentElement.remove();
  }

  container.querySelector("#intro-video-remove-button").addEventListener("click", () => {
    if (confirm(localize({
      en: "Are you sure you want to delete the intro video?",
      sv: "Är du säker på att du vill ta bort introduktionsvideon?"
    }))) {
      console.log("Delete intro video");
      config.clearIntroVideo();
      refreshIntroVideoViews();
    }
  });
  if (isReadonly || !isAdmin) {
    container.querySelector("#intro-video-remove-button").parentElement.remove();
  }

  // EXTRA RESOURCES
  function refreshExtraResourcesViews() {
    if (config.getExtraResourcesList() === undefined || config.getExtraResourcesList() == null || config.getExtraResourcesList().length == 0) {
      container.querySelector("#extra-resources-placeholder").classList.remove("is-hidden");
      container.querySelector("#extra-resources-container").classList.add("is-hidden");
    } else {
      container.querySelector("#extra-resources-placeholder").classList.add("is-hidden");
      const resourceContainer = container.querySelector("#extra-resources-container");
      resourceContainer.classList.remove("is-hidden");

      resourceContainer.removeAllChildren();
      config.getExtraResourcesList().forEach((extraResource) => {
        const col = document.createElement("div");
        col.classList.add("column", "is-6");
        const box = document.createElement("div");
        box.classList.add("box");

        const deleteButton = document.createElement("a");
        deleteButton.classList.add("button", "is-danger", "is-outlined", "is-pulled-right");
        deleteButton.innerText = localize({en: "Delete", sv: "Ta bort"});
        deleteButton.addEventListener("click", () => {
          if (!confirm(localize({
            en: "Are you sure you want to delete this extra resource?",
            sv: "Är du säker på att du vill ta bort denna extraresursen?"
          }))) {
            return;
          }
          const newResources = [];
          config.getExtraResourcesList().forEach((extra) => {
            if (extra.getUuid() != extraResource.getUuid()) {
              newResources.push(extra);
            }
          })
          config.clearExtraResourcesList();
          config.setExtraResourcesList(newResources);
          refreshExtraResourcesViews();
        });

        if (!isReadonly && isAdmin) {
          box.appendChild(deleteButton);
        }

        box.appendChild(EditMediaItem({
          mediaItem: extraResource,
          isReadonly: isReadonly,
          isAdmin: isAdmin,
        }))

        col.appendChild(box);
        resourceContainer.appendChild(col);
      });
    }
  }
  refreshExtraResourcesViews();

  container.querySelector("#extra-resources-add-button").addEventListener("click", () => {
    console.log("Create new extra resource");
    const resource = new MediaItem();
    resource.setUuid(uuidv4());
    resource.setType(MediaItem.MediaItemType.VIDEO);
    config.addExtraResources(resource);
    refreshExtraResourcesViews();
  });
  if (isReadonly || !isAdmin) {
    container.querySelector("#extra-resources-add-button").parentElement.remove();
  }

  // DAYS
  function refreshDaysViews() {
    if (config.getDaysList() === undefined || config.getDaysList() == null || config.getDaysList().length == 0) {
      container.querySelector("#days-placeholder").classList.remove("is-hidden");
      container.querySelector("#days-container").classList.add("is-hidden");
      container.querySelector("#days-change-order").parentElement.classList.add("is-hidden");
    } else {
      container.querySelector("#days-placeholder").classList.add("is-hidden");
      container.querySelector("#days-change-order").parentElement.classList.remove("is-hidden");
      const daysContainer = container.querySelector("#days-container");
      daysContainer.classList.remove("is-hidden");

      daysContainer.removeAllChildren();
      config.getDaysList().forEach((day, idx) => {
        const col = document.createElement("div");
        col.classList.add("column", "is-12");
        const box = document.createElement("div");
        box.classList.add("box");

        const deleteButton = document.createElement("a");
        deleteButton.classList.add("button", "is-danger", "is-outlined", "is-pulled-right");
        deleteButton.style.bottom = "2.25rem";
        deleteButton.innerText = localize({en: "Delete day", sv: "Ta bort dag"});
        deleteButton.addEventListener("click", () => {
          if (!confirm(localize({
            en: "Are you sure you want to delete this day? Everything contained within it will be removed!",
            sv: "Är du säker på att du vill ta bort denna dagen? Allt som ingår i den kommer tas bort!"
          }))) {
            return;
          }
          const newDays = [];
          config.getDaysList().forEach((d) => {
            if (d.getUuid() != day.getUuid()) {
              newDays.push(d);
            }
          })
          config.clearDaysList();
          config.setDaysList(newDays);
          refreshDaysViews();
        });

        box.appendChild(EditDay(day, idx + 1, config, { isReadonly: isReadonly, isAdmin: isAdmin }));
        if (!isReadonly && isAdmin) {
          box.appendChild(deleteButton);
        }

        col.appendChild(box);
        daysContainer.appendChild(col);
      });
    }
  }
  refreshDaysViews();

  container.querySelector("#days-change-order").addEventListener("click", () => {
    new DaysOrderModal(container, config.getDaysList(), {
      onSubmit: (newDays) => {
        config.clearDaysList();
        config.setDaysList(newDays);
        refreshDaysViews();
      }
    }).show();
  });
  if (isReadonly) {
    container.querySelector("#days-change-order").parentElement.remove();
  }

  container.querySelector("#days-add-new").addEventListener("click", () => {
    const newDay = new Day();
    newDay.setUuid(uuidv4());
    config.addDays(newDay);
    refreshDaysViews();
    const last = container.querySelector("#days-container").lastChild;
    last.style.animationDelay = "250ms";
    last.classList.add("animated", "pulse");
    last.scrollIntoView();
  });
  if (isReadonly || !isAdmin) {
    container.querySelector("#days-add-new").parentElement.remove();
  }
}

function refreshLock(courseUUID) {
  patchCourseLock(courseUUID)
  .then(() => console.log("Refreshed course lock"))
  .catch((err) => console.log("Failed to refresh course lock:", err))
}

function createNewDailyReviewQustion(question, negativeLabel, positiveLabel, { uuid = uuidv4() } = {}) {
  const newQuestion = new Course.Question();
  newQuestion.setQuestion(question);
  newQuestion.setMinLabel(negativeLabel);
  newQuestion.setMaxLabel(positiveLabel);
  newQuestion.setUuid(uuid);
  return newQuestion;
}

function isCourseFinalized(course) {
  return course.getState() == EditableCourse.State.FINALIZED;
}

function isCourseDeleted(course) {
  return course.getState() == EditableCourse.State.DELETED;
}