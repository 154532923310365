import template from './modal.html';

export class Modal {
  constructor(container, {
    title = "",
    description = "",
    positiveButton = "",
    negativeButton = "",
    neutralButton = "",
    onPositive = () => {},
    onNegative = () => {},
    onCancel = () => {},
  } = {}) {
    this.container = container;
    this.view = document.createElement("div");
    this.view.classList.add("modal", "animated", "fadeIn");
    this.view.style.animationDuration = "250ms";
    this.view.innerHTML = template({
      title: title,
      description: description,
      positive_button: positiveButton,
      negative_button: negativeButton,
      neutral_button: neutralButton
    });

    if (positiveButton == "") {
      this.view.querySelector(".save-modal-button").classList.add("is-hidden");
    }
    if (negativeButton == "") {
      this.view.querySelector(".delete-modal-button").classList.add("is-hidden");
    }
    if (neutralButton == "") {
      this.view.querySelector(".modal-card-foot").querySelector(".cancel-modal-button").classList.add("is-hidden");
    }

    this.onPositive = onPositive;
    this.onNegative = onNegative;
    this.onCancel = onCancel;

    this.view.querySelectorAll(".cancel-modal-button").forEach((match) => {
      match.addEventListener("click", () => {
        this.onCancel();
        this.destroy();
      })
    });
    this.view.querySelector(".save-modal-button").addEventListener("click", () => {
      this.onPositive();
    });
    this.view.querySelector(".delete-modal-button").addEventListener("click", () => {
      this.onNegative();
      this.destroy();
    });

    this.container.appendChild(this.view);
  }

  addContent(contentNode) {
    this.view.querySelector(".modal-content-container").appendChild(contentNode);
  }

  show() {
    document.querySelector("html").classList.add("is-clipped");
    this.view.classList.add("is-active");
  }

  destroy() {
    document.querySelector("html").classList.remove("is-clipped");
    try {
      this.view.remove()
    } catch (error) {
      console.log("Could not remove modal:", error)
    }
  }
}