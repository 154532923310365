import template from './index.html';
import localize from 'localize';

import { navigateTo } from 'app';
import * as authToken from 'authToken';

import { deleteCourse, patchCourseEditable, patchCourseFinalized, postCourseLock, patchCourseLock } from 'api/tmyConfigEditorV1';

const strings = {
  en: {
    edit: "Edit",
    view: "View",
    finalized: "Finalized",
    locked: "Locked",
    deleted: "Deleted",
    delete_course: "Delete course",
    finalize_course: "Finalize course",
    last_edited: "Last edited",
    being_edited: "Currently being edited by",
    scope: "Scope",
  },
  sv: {
    edit: "Redigera",
    view: "Visa",
    finalized: "Färdigställd",
    locked: "Låst",
    deleted: "Borttagen",
    delete_course: "Ta bort kurs",
    finalize_course: "Färdigställ kurs",
    last_edited: "Senast redigerad",
    being_edited: "Redigeras just nu av",
    scope: "Scope",
  }
};

export default function Setup(course, lock, isAdmin, onChange) {
  const container = document.createElement("div");

  container.innerHTML = template({
    t: localize(strings),
    title: course.getTitle(),
    description: course.getDescription(),
    last_edited: (new Date(course.getLastEditedAt() * 1000)).toISODateStringWithTime(),
    lock_owner: lock == null ? "" : lock.getUserName(),
    scope: course.getScope(),
  });

  const finalizedIcon = container.querySelector(".icon-finalized");
  const lockedIcon = container.querySelector(".icon-locked");

  const lastEditedText = container.querySelector(".last-edited-text");
  const beingEditedText = container.querySelector(".currently-editing-text");

  const viewButton = container.querySelector(".view-course");
  viewButton.addEventListener("click", (ev) => {
    navigateTo("view_course", { course_uuid: course.getUuid() });
    ev.stopPropagation();
  });

  const editButton = container.querySelector(".edit-course");
  editButton.addEventListener("click", (ev) => {
    if (editButton.getAttribute("disabled") === "disabled") {
      ev.stopPropagation();
      return
    }
    if (lock != null && lock.getUserUuid() == authToken.userUUID()) {
      navigateTo("edit_course", { course_uuid: course.getUuid() });
      return;
    }
    editButton.classList.add("is-loading");
    postCourseLock(course.getUuid())
    .then(() => {
      navigateTo("edit_course", { course_uuid: course.getUuid() });
    })
    .catch((err) => {
      console.log("Failed to create course lock:", err);
      if (!isAdmin) {
        alert(localize({en: "Course is locked for editing.", sv: "Kursen är låst för redigering."}))
      } else if (confirm(localize({en: "Course is locked. Override lock?", sv: "Kursen är låst. Ta över låset?"}))) {
          patchCourseLock(course.getUuid())
          .then(() => navigateTo("edit_course", { course_uuid: course.getUuid() }))
          .catch((err) => {
            console.log("Failed to override lock:", err);
            alert(localize({en: "Failed to override lock", sv: "Kunde inte ta över låset"}));
          })
      }
    })
    .finally(() => editButton.classList.remove("is-loading"))
    ev.stopPropagation();
  });

  const deleteButton = container.querySelector(".delete-course");
  deleteButton.addEventListener("click", (ev) => {
    deleteButton.classList.add("is-loading");
    if (isCourseDeleted(course)) {
      // Make editable
      patchCourseEditable(course.getUuid())
      .then(() => {
        onChange(false);
      })
      .catch((err) => {
        console.log("Failed to make course editable", err);
        // TODO: Show error message
      })
      .finally(() => finalizeButton.classList.remove("is-loading"))
    } else {
      // Delete
      deleteCourse(course.getUuid())
        .then(() => {
          onChange(false);
        })
        .catch((err) => {
          console.log("Failed to delete course", err);
          // TODO: Show error message
        })
        .finally(() => finalizeButton.classList.remove("is-loading"))
    }
    ev.stopPropagation();
  });

  const finalizeButton = container.querySelector(".finalize-course");
  finalizeButton.addEventListener("click", (ev) => {
    finalizeButton.classList.add("is-loading");
    if (isCourseFinalized(course)) {
      // Make editable
      patchCourseEditable(course.getUuid())
        .then(() => {
          onChange(false);
        })
        .catch((err) => {
          console.log("Failed to make course editable", err);
          // TODO: Show error message
        })
        .finally(() => finalizeButton.classList.remove("is-loading"))
    } else {
      // Finalize
      patchCourseFinalized(course.getUuid())
        .then(() => {
          onChange(false);
        })
        .catch((err) => {
          console.log("Failed to make course finalized", err);
          // TODO: Show error message
        })
        .finally(() => finalizeButton.classList.remove("is-loading"))
    }
    ev.stopPropagation();
  });

  if (isAdmin) {
    viewButton.parentElement.classList.remove("is-marginless");
    deleteButton.parentElement.classList.remove("is-hidden");
    finalizeButton.parentElement.classList.remove("is-hidden");
    container.querySelector(".scope-tag").parentElement.classList.remove("is-hidden");
  }

  if (!course.getUserCanEdit()) {
    editButton.parentElement.classList.add("is-hidden");
  }

  if (isCourseFinalized(course)) {
    finalizedIcon.parentElement.classList.remove("is-hidden");
    finalizeButton.classList.remove("is-success");
    finalizeButton.classList.add("is-danger");
    finalizeButton.setAttribute("title", localize({en: "Make editable", sv: "Gör redigerbar"}))

    if (!isAdmin) {
      editButton.setAttribute("disabled", "disabled");
    }
  } else if (isCourseDeleted(course)) {
    container.querySelector(".is-deleted-tag").parentElement.classList.remove("is-hidden");
    deleteButton.classList.remove("is-danger");
    deleteButton.classList.add("is-success");
    deleteButton.setAttribute("title", localize({en: "Make editable", sv: "Gör redigerbar"}))
  }

  if (lock != null) {
    lockedIcon.parentElement.classList.remove("is-hidden");
    lastEditedText.classList.add("is-hidden");
    beingEditedText.classList.remove("is-hidden");

    if (!isAdmin && lock.getUserUuid() != authToken.userUUID()) {
      editButton.setAttribute("disabled", "disabled");
    }
  }

  return container;
}

function isCourseFinalized(course) {
  return course.getState() == proto.API.TMY.ConfigEditor.V1.Course.State.FINALIZED;
}

function isCourseDeleted(course) {
  return course.getState() == proto.API.TMY.ConfigEditor.V1.Course.State.DELETED;
}