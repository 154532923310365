Node.prototype.removeAllChildren = function() {
  while (this.hasChildNodes()) {
    this.removeChild(this.firstChild);
  }
}

Node.prototype.replaceChildren = function(newChild) {
  this.removeAllChildren();
  this.appendChild(newChild);
}

window.sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

Date.prototype.toISODateString = function() {
  return this.toISOString().slice(0, 10);
}

Date.prototype.toISODateStringWithTime = function() {
  const isoString = this.toISOString();
  const datePart = isoString.slice(0, 10);
  const timePart = isoString.slice(11, isoString.length-1).split(":");
  // e.g. 14:27, 2019-05-30
  return timePart[0] + ":" + timePart[1] +  ", " + datePart;
}

Date.prototype.toTimeString = function() {
  const isoString = this.toISOString();
  const timePart = isoString.slice(11, isoString.length-1).split(":");
  // e.g. 14:27:05
  return timePart[0] + ":" + timePart[1] +  ":" + timePart[2].split(".")[0];
}