import template from './index.html';

import localize from 'localize';
import uuidv4 from 'uuid';
import EditMediaItem from 'components/edit_media_item/';
import EditLoggingConfigItem from 'components/edit_logging_task_config/';

import { Task } from 'tummylab-protobuf/js/program.v1/program_config_pb';
import { MediaItem } from 'tummylab-protobuf/js/program.v1/media_item_pb';
import { ManuallyConfirmed, DailyReview, Logging, MediaPlayback, AutoCompleted, WeightCheck, Survey } from 'tummylab-protobuf/js/program.v1/program_config/task_config_pb';

const strings = {
  en: {
    title_label: "Task",
    description_label: "Extra description",
    optional: "optional",
    hidden: "Hidden",
    hidden_desc: "This task is hidden until the previous task is closed",
    required: "Required",
    required_desc: "This task must be completed before the day can be completed",
    config: {
      label: "Config type",
      manual: "Manually Confirmed",
      daily_review: "Daily Review",
      logging: "Logging",
      media: "Media Playback",
      auto: "Automatically Completed",
      weight: "Weight Check",
      survey: "Survey",
    },
  },
  sv: {
    title_label: "Uppgift",
    description_label: "Extra beskrivning",
    optional: "frivilligt",
    hidden: "Gömd",
    hidden_desc: "Denna uppgiften är dold tills den föregående uppgiften är stängd",
    required: "Krävs",
    required_desc: "Denna uppgiften måste slutföras innan dagen kan slutföras",
    config: {
      label: "Konfigurationstyp",
      manual: "Manuellt Bekräftad",
      daily_review: "Daglig Utvärdering",
      logging: "Loggning",
      media: "Mediavisning",
      auto: "Automatiskt Slutförd",
      weight: "Viktkontroll",
      survey: "Enkät",
    },
  }
}

export default function Setup(task, { isReadonly = false, isAdmin = false } = {}) {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings),
    task: {
      title: task.getTitle(),
      description: task.getDescription(),
    }
  });

  if (isReadonly) {
    container.querySelectorAll("input").forEach((match) => {
      match.classList.add("is-static");
      match.setAttribute("readonly", "readonly");
    });
  }

  // Setup toggle buttons
  if (task.getIsHiddenWhilePreviousTasksAreOpen()) {
    container.querySelector(".task-hidden").classList.remove("is-outlined");
  } else if (isReadonly) {
    container.querySelector(".task-hidden").setAttribute("disabled", "disabled");
  }
  container.querySelector(".task-hidden").addEventListener("click", (ev) => {
    if (!isReadonly) {
      task.setIsHiddenWhilePreviousTasksAreOpen(!task.getIsHiddenWhilePreviousTasksAreOpen());
      ev.target.classList.toggle("is-outlined");
    }
  });

  if (task.getRequiredToProceedFromDay()) {
    container.querySelector(".task-required").classList.remove("is-outlined");
  } else if (isReadonly) {
    container.querySelector(".task-required").setAttribute("disabled", "disabled");
  }
  container.querySelector(".task-required").addEventListener("click", (ev) => {
    if (!isReadonly) {
      task.setRequiredToProceedFromDay(!task.getRequiredToProceedFromDay());
      ev.target.classList.toggle("is-outlined");
    }
  });

  // Setup title and description editing
  container.querySelector(".task-title").addEventListener("input", (ev) => {
    console.log("Updated task title to", ev.target.value);
    task.setTitle(ev.target.value);
  })

  container.querySelector(".task-description").addEventListener("input", (ev) => {
    console.log("Updated task description to", ev.target.value);
    task.setDescription(ev.target.value);
  });

  container.querySelector(".task-config").value = taskConfigToString(task);
  const configContainer = container.querySelector(".task-config-container");
  function refreshConfigViews(configType) {
    configContainer.removeAllChildren();
    let shouldCreateNew = configType != taskConfigToString(task);
    switch (configType) {
      case "manual":
        if (shouldCreateNew) {
          task.setManuallyConfirmedConfig(new ManuallyConfirmed());
        }
        populateManuallyConfirmed(configContainer);
        break;
      case "daily_review":
        if (shouldCreateNew) {
          task.setDailyReviewConfig(new DailyReview());
        }
        populateDailyReview(configContainer);
        break;
      case "logging":
        if (shouldCreateNew) {
          const loggingConfig = new Logging();
          loggingConfig.setCategory(Logging.LoggingCategory.ACTIVITY); // Some default
          task.setLoggingConfig(loggingConfig);
        }
        populateLogging(configContainer, task.getLoggingConfig(), { isReadonly: isReadonly, isAdmin: isAdmin });
        break;
      case "media":
        if (shouldCreateNew) {
          const mediaConfig = new MediaPlayback();
          const mediaItem = new MediaItem();
          mediaItem.setUuid(uuidv4());
          mediaItem.setType(MediaItem.MediaItemType.VIDEO);
          mediaConfig.setMediaItem(mediaItem);
          task.setMediaPlaybackConfig(mediaConfig);
        }
        populateMediaPlayback(configContainer, task.getMediaPlaybackConfig(), { isReadonly: isReadonly, isAdmin: isAdmin });
        break;
      case "auto":
        if (shouldCreateNew) {
          task.setAutoCompletedConfig(new AutoCompleted());
        }
        populateAutoCompleted(configContainer);
        break;
      case "weight":
        if (shouldCreateNew) {
          task.setWeightCheckConfig(new WeightCheck());
        }
        populateWeightCheck(configContainer);
        break;
      case "survey":
        populateSurvey(configContainer);
        break;
      default:
        throw "unknown config case " + configType;
    }
  }
  refreshConfigViews(taskConfigToString(task));
  container.querySelector(".task-config").addEventListener("change", (ev) => {
    console.log("Changing task config type to", ev.target.value);
    refreshConfigViews(ev.target.value);
  });
  if (isReadonly) {
    container.querySelector(".task-config").setAttribute("disabled", "disabled");
  }

  if (!isReadonly && !isAdmin) {
    container.querySelectorAll(".non-editable-by-normal").forEach((el) => {
      el.remove();
    });
  }

  return container;
}

function populateLogging(container, config, { isReadonly = false, isAdmin = false } = {}) {
  const divider = document.createElement("hr");
  container.appendChild(divider);
  const editLogging = EditLoggingConfigItem(config, { isReadonly: isReadonly, isAdmin: isAdmin });
  container.appendChild(editLogging);
}

function populateMediaPlayback(container, config, { isReadonly = false, isAdmin = false } = {}) {
  const divider = document.createElement("hr");
  container.appendChild(divider);
  const editMedia = EditMediaItem({
    mediaItem: config.getMediaItem(),
    isReadonly: isReadonly,
    isAdmin: isAdmin,
  });
  container.appendChild(editMedia);
}

function populateManuallyConfirmed(container) {
  const explanation = document.createElement("p");
  explanation.innerText = localize({
    en: "This task will have to be manually confirmed by the user at the end of their day.",
    sv: "Denna uppgiften måste manuellt bekräftas av användaren i slutet av deras dag."
  });
  container.appendChild(explanation);
}

function populateDailyReview(container) {
  const explanation = document.createElement("p");
  explanation.innerText = localize({
    en: "This task will allow the user to complete their daily review.",
    sv: "Denna uppgiften låter användaren göra sin dagliga utvärdering."
  });
  container.appendChild(explanation);
}

function populateAutoCompleted(container) {
  const explanation = document.createElement("p");
  explanation.innerText = localize({
    en: "This task will be automatically completed as soon as it's shown.",
    sv: "Denna uppgiften kommer automatiskt slutföras så fort den visas."
  });
  container.appendChild(explanation);
}

function populateWeightCheck(container) {
  const explanation = document.createElement("p");
  explanation.innerText = localize({
    en: "This task prompts the user to check and input their weight.",
    sv: "Denna uppgiften ber användaren att kontrollera och skriva in sin vikt."
  });
  container.appendChild(explanation);
}

function populateSurvey(container) {
  const body = document.createElement("div");
  body.classList.add("notification", "is-danger");
  const explanation = document.createElement("p");
  explanation.innerText = localize({
    en: "Editing survey tasks is not currently supported. The previously selected task type still holds.",
    sv: "Det går för närvarande inte att redigera enkät-uppgifter. Den tidigare valda uppgiftstypen gäller fortfarande."
  });
  body.appendChild(explanation);
  container.appendChild(body);
}

function taskConfigToString(task) {
  switch (task.getConfigCase()) {
    case Task.ConfigCase.MANUALLY_CONFIRMED_CONFIG:
      return "manual";
    case Task.ConfigCase.DAILY_REVIEW_CONFIG:
      return "daily_review";
    case Task.ConfigCase.LOGGING_CONFIG:
      return "logging";
    case Task.ConfigCase.MEDIA_PLAYBACK_CONFIG:
      return "media";
    case Task.ConfigCase.AUTO_COMPLETED_CONFIG:
      return "auto";
    case Task.ConfigCase.WEIGHT_CHECK_CONFIG:
      return "weight";
    case Task.ConfigCase.SURVEY_CONFIG:
      return "survey";
    default:
      throw "unknown config case " + task.getConfigCase();
  }
}
