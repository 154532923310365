import * as rh from './_responseHandlers';
import * as tmy from './_tmyHelpers';
import * as authToken from '../authToken';

import * as responses from 'tummylab-protobuf/js/api.tmy.config_editor.v1/responses_pb';
import * as requests from 'tummylab-protobuf/js/api.tmy.config_editor.v1/requests_pb';

const API_NAME = "tmy.config_editor.v1";

export function getCourses() {
  return tmy.get("/courses", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.GetCourses", responses.GetCourses))
    .then((resp) => {
      return resp.getCoursesList();
    });
}

export function getCourse(courseUUID) {
  return tmy.get("/courses/" + courseUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.GetCourse", responses.GetCourse))
    .then((resp) => {
      return resp.getCourse();
    });
}

export function patchCourse(courseUUID, newCourseConfig) {
  const msg = new requests.PatchCourse()
  msg.setConfig(newCourseConfig);
  return tmy.patch("/courses/" + courseUUID, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PatchCourse"
  })
    .then(rh.checkStatus)
}

export function deleteCourse(courseUUID) {
  return tmy.del("/courses/" + courseUUID, undefined, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function patchCourseEditable(courseUUID) {
  return tmy.patch("/courses/" + courseUUID + "/editable", undefined, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function patchCourseFinalized(courseUUID) {
  return tmy.patch("/courses/" + courseUUID + "/finalized", undefined, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function putCourseResources(courseUUID, resourcesList) {
  const req = new requests.PutCourseExternalResources();
  req.setExternalResourcesList(resourcesList);
  return tmy.put("/courses/" + courseUUID + "/resources", req.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PutCourseExternalResources"
  })
    .then(rh.checkStatus)
}

export function createCourse(scope, courseMessage) {
  const message = new requests.PostCourse();
  message.setScope(scope);
  message.setConfig(courseMessage);
  return tmy.post("/courses/", message.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PostCourse"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.PostCourse", responses.PostCourse))
    .then((resp) => {
      return resp.getCourse();
    })
}

export function getCourseLock(courseUUID) {
  return tmy.get("/course_locks/" + courseUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.GetCourseLock", responses.GetCourseLock))
    .then((resp) => {
      return resp.getLock();
    });
}

export function postCourseLock(courseUUID) {
  const msg = new requests.PostCourseLock();
  msg.setCourseUuid(courseUUID);
  return tmy.post("/course_locks/", msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PostCourseLock"
  })
    .then(rh.checkStatus)

}

export function patchCourseLock(courseUUID) {
  return tmy.patch("/course_locks/" + courseUUID, undefined, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function deleteCourseLock(courseUUID) {
  return tmy.del("/course_locks/" + courseUUID, undefined, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function getPrograms() {
  return tmy.get("/programs", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.GetPrograms", responses.GetPrograms))
    .then((resp) => {
      return resp.getProgramsList();
    });
}

export function getProgram(programUUID) {
  return tmy.get("/programs/" + programUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.GetProgram", responses.GetProgram))
    .then((resp) => {
      return resp.getProgram();
    });
}

export function patchProgram(programUUID, newProgramConfig) {
  const msg = new requests.PatchProgram();
  msg.setConfig(newProgramConfig);
  return tmy.patch("/programs/" + programUUID, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PatchProgram"
  })
    .then(rh.checkStatus)
}

export function patchProgramCourses(programUUID, courseUUIDList) {
  const msg = new requests.PatchProgramCourses();
  msg.setCourseUuidsList(courseUUIDList);
  return tmy.patch("/programs/" + programUUID + "/courses", msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PatchProgramCourses"
  })
    .then(rh.checkStatus)
}

export function getProgramLock(programUUID) {
  return tmy.get("/program_locks/" + programUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.GetProgramLock", responses.GetProgramLock))
    .then((resp) => {
      return resp.getLock();
    });
}

export function postProgramLock(programUUID) {
  const msg = new requests.PostProgramLock();
  msg.setProgramUuid(programUUID);
  return tmy.post("/program_locks/", msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PostProgramLock"
  })
    .then(rh.checkStatus)
}

export function patchProgramLock(programUUID) {
  return tmy.patch("/program_locks/" + programUUID, undefined, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function deleteProgramLock(programUUID) {
  return tmy.del("/program_locks/" + programUUID, undefined, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function createProgram(scope, programMessage, ordinalTitlePrefix) {
  const message = new requests.PostProgram();
  message.setScope(scope);
  message.setConfig(programMessage);
  message.setOrdinalTitlePrefix(ordinalTitlePrefix);
  return tmy.post("/programs/", message.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.ConfigEditor.V1.Requests.PostProgram",
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.ConfigEditor.V1.Responses.PostProgram", responses.PostProgram))
    .then((resp) => {
      return resp.getProgram();
    });
}

export function assembleProgram(programUUID) {
  return tmy.get("/programs/" + programUUID + "/assemble", {
    apiName: API_NAME,
    authToken: authToken.get(),
  })
    .then(rh.checkStatus)
}