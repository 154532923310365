import template from './index.html';
import taskItem from './task_item.html';

import MissingImage from '../../../img/image-missing.png';
import localize from 'localize';
import EditTask from 'components/edit_task';
import { Modal } from 'components/modal/modal';
import uuidv4 from 'uuid';

import Sortable from 'sortablejs';

import { Task } from 'tummylab-protobuf/js/program.v1/program_config_pb';
import { ManuallyConfirmed } from 'tummylab-protobuf/js/program.v1/program_config/task_config_pb';

const strings = {
  en: {
    day: "Day",
    title_label: "Title",
    title_tooltip: "This day's title",
    description_label: "Description",
    description_tooltip: `Tell the patient what the daily material is about, what the patient should do today or what your thoughts about today's subject are.

    Don't forget to add a personal touch like ending with "lycka till idag!"`,
    image_url_label: "Day image",
    invalid_url: "Invalid URL",
    tasks_label: "Tasks",
    tasks_tooltip: `During each day, patients will be completing tasks to be able to continue with the next day. Your task is to come up with tasks for the patient to complete. E.g:

      • Logga något du har ätit eller druckit idag.

      • Försök att undvika gluten idag

      • Gör dina övningar två gånger under dagen`,
    add_task: "Add new task",
  },
  sv: {
    day: "Dag",
    title_label: "Rubrik",
    title_tooltip: "Denna dagens rubrik",
    description_label: "Beskrivning",
    description_tooltip: `Förklara för patienten vad dagens material handlar om, vad patienten ska göra idag eller om beskriv dina tankar kring dagens ämne.

    Glöm inte att lägga till en personlig touch, såsom att t.ex. avsluta med "Lycka till idag!".`,
    image_url_label: "Dagens bild",
    invalid_url: "Felaktig länk",
    tasks_label: "Uppgifter",
    tasks_tooltip: `Under varje dag behöver patenter slutföra uppgifter för att kunna gå vidare till nästa dag i veckan. Din uppgift är att komma på uppgifter som patienterna ska slutföra. Exempelvis:

    • Logga något du har ätit eller druckit idag.

    • Försök att undvika gluten idag

    • Gör dina övningar två gånger under dagen`,
    add_task: "Lägg till uppgift",
  }
}


export default function Setup(day, dayNumber, parentCourse, {
  onUpdated = (day) => console.log("Updated day:", day),
  isReadonly = false,
  isAdmin = false,
} = {}) {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings),
    day: {
      title: day.getTitle(),
      description: day.getDescription(),
      image_url: day.getImageUrl(),
      number: dayNumber,
      course_ordinal: parentCourse.getOrdinalTitle(),
    }
  });

  container.querySelectorAll(".tooltip-text").forEach((match) => {
    match.innerText = match.innerText.trim();
  });

  if (isReadonly) {
    container.querySelectorAll("input").forEach((match) => {
      match.classList.add("is-static");
      match.setAttribute("readonly", "readonly");
    });

    container.querySelectorAll(".hidden-in-readonly").forEach((match) => {
      match.classList.add("is-hidden");
    });

    container.querySelectorAll("textarea").forEach((match) => {
      match.classList.add("is-static");
      match.style.borderStyle = "none";
      const newText = document.createElement("p");
      newText.innerText = match.value;
      match.parentElement.appendChild(newText);
      newText.style.marginTop = "0.35rem";
      match.classList.add("is-hidden");
    });
  } else if (isAdmin) {
    container.querySelectorAll(".admin-only").forEach((match) => {
      match.classList.remove("is-hidden");
    });
  }

  // Setup image editing
  container.querySelector(".day-image").addEventListener("error", (ev) => {
    container.querySelector(".day-image-url").classList.add("is-danger");
    container.querySelector(".day-image-url-error").classList.remove("is-hidden");
    if (ev.target.currentSrc.trim() == "") {
      container.querySelector(".day-image").setAttribute("src", MissingImage);
    }
    console.log("Failed to load image:", ev);
  });

  container.querySelector(".day-image").addEventListener("load", (ev) => {
    container.querySelector(".day-image-url").classList.remove("is-danger");
    container.querySelector(".day-image-url-error").classList.add("is-hidden");
  });

  container.querySelector(".day-image-url").addEventListener("change", (ev) => {
    console.log("Updated media item thumbnail image URL to:", ev.target.value);
    container.querySelector(".day-image").setAttribute("src", ev.target.value);
    day.setImageUrl(ev.target.value);
    onUpdated(day);
  });

  // Setup title and description editing
  container.querySelector(".day-title").addEventListener("change", (ev) => {
    console.log("Updated day title to:", ev.target.value);
    day.setTitle(ev.target.value);
    onUpdated(day);
  });

  container.querySelector(".day-description").addEventListener("change", (ev) => {
    console.log("Updated day description to:\n" + ev.target.value);
    day.setDescription(ev.target.value);
    onUpdated(day);
  });

  // Setup task editing
  const tasksContainer = container.querySelector(".tasks-container");
  function refreshTasks() {
    tasksContainer.removeAllChildren();
    day.getTasksList().forEach((task) => {
      const taskContainer = document.createElement("div");
      taskContainer.classList.add("box", "task-item");
      const taskIcon = iconWithDescriptionForTask(task);
      taskContainer.innerHTML = taskItem({
        uuid: task.getUuid(),
        title: task.getTitle(),
        description: task.getDescription(),
        icon: taskIcon.icon,
        icon_desc: taskIcon.description,
        t: localize({
          en: {
            hidden: "HIDDEN",
            hidden_desc: "This task is hidden until the previous task is closed",
            required: "REQUIRED",
            required_desc: "This task must be completed before the day can be completed",
          },
          sv: {
            hidden: "GÖMD",
            hidden_desc: "Denna uppgiften är dold tills den föregående uppgiften är stängd",
            required: "KRÄVS",
            required_desc: "Denna uppgiften måste slutföras innan dagen kan slutföras",
          }
        })
      });

      if (isReadonly || !(isAdmin || isTaskEditableByNormalUser(task))) {
        const editButton = taskContainer.querySelector(".task-edit-icon");
        editButton.classList.remove("fa-pen");
        editButton.classList.add("fa-eye");
      }

      if (task.getIsHiddenWhilePreviousTasksAreOpen()) {
        taskContainer.querySelector(".task-hidden").classList.remove("is-hidden");
      }

      if (task.getRequiredToProceedFromDay()) {
        taskContainer.querySelector(".task-required").classList.remove("is-hidden");
      }

      taskContainer.querySelector("a").addEventListener("click", () => {
        console.log("Edit task:", task.getUuid());

        let modalTitle = localize({en: "Edit task", sv: "Redigera uppgift"});
        let positiveButton = localize({en: "Save task", sv: "Spara uppgift"});
        let negativeButton = localize({en: "Delete task", sv: "Ta bort uppgift"});
        if (isReadonly) {
          modalTitle = localize({en: "View task", sv: "Visa uppgift"});
          positiveButton = "";
          negativeButton = "";
        }
        const body = EditTask(task, { isReadonly: isReadonly || !(isAdmin || isTaskEditableByNormalUser(task)), isAdmin: isAdmin });
        const modal = new Modal(container, {
          title: modalTitle,
          positiveButton: positiveButton,
          neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
          negativeButton: negativeButton,
          onPositive: () => {
            modal.destroy();
            refreshTasks();
          },
          onNegative: () => {
            const newTasks = [];
            day.getTasksList().forEach((t) => {
              if (t.getUuid() != task.getUuid()) {
                newTasks.push(t);
              }
            });
            day.clearTasksList();
            day.setTasksList(newTasks);
            refreshTasks();
          }
        });

        modal.addContent(body);
        modal.show();
      });
      tasksContainer.appendChild(taskContainer);
    });
  }
  refreshTasks();

  if (!isReadonly) {
    Sortable.create(tasksContainer, {
      animation: 150,
      onEnd: () => {
        const newTasks = [];
        tasksContainer.querySelectorAll(".task-item").forEach((match) => {
          const uuid = match.querySelector(".task-uuid").innerText;
          let foundTask = false;
          day.getTasksList().forEach((task) => {
            if (uuid == task.getUuid()) {
              newTasks.push(task);
              foundTask = true;
            }
          });
          if (!foundTask) {
            throw "Lost track of task with uuid: " + uuid;
          }
        });
        day.clearTasksList();
        day.setTasksList(newTasks);
        refreshTasks();
      }
    });
  }

  container.querySelector(".day-add-task").addEventListener("click", () => {
    console.log("Create new task");
    const newTask = new Task();
    newTask.setUuid(uuidv4());
    // Set some default config
    newTask.setManuallyConfirmedConfig(new ManuallyConfirmed());
    const body = EditTask(newTask, { isReadonly: isReadonly, isAdmin: isAdmin });
    const modal = new Modal(container, {
      title: localize({en: "Create new task", sv: "Skapa ny uppgift"}),
      positiveButton: localize({en: "Add new task", sv: "Lägg till ny uppgift"}),
      neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
      onPositive: () => {
        day.addTasks(newTask);
        modal.destroy();
        refreshTasks();
      }
    });

    modal.addContent(body);
    modal.show();
  });
  if (isReadonly) {
    container.querySelector(".day-add-task").remove();
  }

  return container;
}

function isTaskEditableByNormalUser(task) {
  switch(task.getConfigCase()) {
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.MEDIA_PLAYBACK_CONFIG:
      return false;
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.SURVEY_CONFIG:
      return false;
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.AUTO_COMPLETED_CONFIG:
      return false;
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.DAILY_REVIEW_CONFIG:
      return false;
    default:
      return true;
  }
}

function iconWithDescriptionForTask(task) {
  switch(task.getConfigCase()) {
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.MANUALLY_CONFIRMED_CONFIG:
      return {icon: "user-check", description: localize({ en: "Manually Confirmed", sv: "Manual Bekräftelse"})};
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.DAILY_REVIEW_CONFIG:
      return {icon: "clipboard-check", description: localize({ en: "Daily Review", sv: "Daglig Utvärdering"})};
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.LOGGING_CONFIG:
      return {icon: "pen", description: localize({ en: "Logging", sv: "Loggning"})};
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.MEDIA_PLAYBACK_CONFIG:
      return {icon: "play", description: localize({ en: "Media Playback", sv: "Mediavisning"})};
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.AUTO_COMPLETED_CONFIG:
      return {icon: "fast-forward", description: localize({ en: "Auto Completed", sv: "Automatisk"})};
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.WEIGHT_CHECK_CONFIG:
      return {icon: "weight", description: localize({ en: "Weight Check", sv: "Viktkontroll"})};
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.SURVEY_CONFIG:
      return {icon: "poll-h", description: localize({ en: "Survey", sv: "Enkät"})};
    case proto.Program.V1.ProgramConfig.Task.ConfigCase.CONFIG_NOT_SET:
    default:
      return {icon: "exclamation-circle", description: localize({ en: "Unknown task type", sv: "Okänd uppgiftstyp"})};
  }
}
