import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';

import '@fortawesome/fontawesome-free/js/all.js';
import './index.scss';

import './extensions.js';

import * as authToken from 'authToken';
import localize from 'localize';

import PageLogin from 'pages/login';
import PageIndex from 'pages/index';
import PageCourses from 'pages/courses';
import PageEditCourse from 'pages/edit_course';
import PagePrograms from 'pages/programs';
import PageEditProgram from 'pages/edit_program';

import ErrorNotification from 'components/notification/error';

export function navigateTo(page, extra = {}) {
  const container = document.querySelector(".page-container");
  refreshContent(container, page, false, extra);
}

let onNavigatedCallbacks = [];
export function addOnNavigatedCallback(callback) {
  onNavigatedCallbacks.push(callback);
}

function refreshContent(container, page, replaceState = false, extra = {}) {
  let shouldAbort = false;
  onNavigatedCallbacks.forEach((callback) => {
    if (!callback(page)) {
      shouldAbort = true;
    }
  });
  if (shouldAbort) {
    console.log("Aborted page navigation to page:", page);
    return;
  }
  onNavigatedCallbacks = [];
  if (replaceState) {
    window.history.replaceState({page: page}, document.title, document.location);
  } else {
    let url = "?page=" + page;
    Object.keys(extra).forEach((key) => {
      url += "&" + key + "=" + extra[key];
    });
    window.history.pushState({page: page}, document.title, url);
  }

  container.removeAllChildren();

  document.querySelector(".navbar-menu").querySelectorAll(".navbar-item").forEach((navItem) => {
    if (authToken.get()) {
      navItem.classList.remove("is-hidden");
    } else {
      navItem.classList.add("is-hidden");
    }
  })

  const logoutMenuItem = document.querySelector(".navbar-item[data-page=_logout]");
  if (!authToken.get()) {
    logoutMenuItem.classList.add("is-hidden");
  } else {
    logoutMenuItem.classList.remove("is-hidden");
  }

  const programsMenuItem = document.querySelector(".navbar-item[data-page=programs]");
  if (authToken.isAdmin()) {
    programsMenuItem.classList.remove("is-hidden");
  } else {
    programsMenuItem.classList.add("is-hidden");
  }

  let currentAuthToken = null;
  try {
    currentAuthToken = authToken.getAndThrowIfExpired();
  } catch(err) {
    authToken.set(null);
    container.replaceChildren(ErrorNotification(err, true));
  }

  if (!currentAuthToken) {
    container.replaceChildren(PageLogin((newAuthToken) => {
      authToken.set(newAuthToken);
      refreshContent(container, page, true);
    }));

    return;
  }

  switch (page) {
    case "courses":
      container.replaceChildren(PageCourses());
      break;
    case "programs":
      container.replaceChildren(PagePrograms());
      break;
    case "view_course":
      container.replaceChildren(PageEditCourse({ isReadonly: true }));
      break;
    case "edit_course":
      container.replaceChildren(PageEditCourse());
      break;
    case "edit_program":
      container.replaceChildren(PageEditProgram());
      break;
    case "index":
    default:
      refreshContent(container, "courses");
      //container.replaceChildren(PageIndex());
      break;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const coursesMenuItem = document.querySelector(".navbar-item[data-page=courses]");
  coursesMenuItem.innerText = localize({sv: "Kurser", en: "Courses"});

  const programsMenuItem = document.querySelector(".navbar-item[data-page=programs]");
  programsMenuItem.innerText = localize({sv: "Program", en: "Programs"});

  const logoutMenuItem       = document.querySelector(".navbar-item[data-page=_logout]");
  logoutMenuItem.innerText = localize({sv: 'Logga ut', en: 'Logout'})

  const title = localize({sv: "Kursverktyg | Tummy Lab", en: "Course Tool | Tummy Lab"});
  document.querySelector("#navigation-title").innerText = title;
  document.querySelector("title").innerText = title;

  const initialPage = require('querystring').parse(window.location.search.substring(1)).page || "index";
  const container = document.querySelector(".page-container");
  refreshContent(container, initialPage, true);

  window.addEventListener("popstate", (ev) => {
    refreshContent(container, event.state.page, true);
  });

  document.querySelectorAll('.main-navigation .navbar-item').forEach((el) => {
    el.addEventListener("click", (ev) => {
      ev.preventDefault();

      const page = el.dataset.page;

      if (page === "_logout") {
        authToken.set(null);
        refreshContent(container, "index");
      } else {
        refreshContent(container, page);
      }
    });
  });

  const navbarBurger = document.querySelector('.navbar-burger');
  navbarBurger.addEventListener('click', function () {
    navbarBurger
      .classList
      .toggle('is-active');
    document
      .getElementById(navbarBurger.dataset.target)
      .classList
      .toggle('is-active');

  });
});
