import template from './index.html';
import whatNowItem from './what_now_item.html';
import contentDescItem from './content_desc_item.html';
import localize from 'localize';
import uuidv4 from 'uuid';
import { addOnNavigatedCallback, navigateTo } from 'app';
import MissingImage from '../../../img/image-missing.png';

import { getProgram, deleteProgramLock, patchProgram, patchProgramCourses, patchProgramLock, getCourses } from 'api/tmyConfigEditorV1';
import { Program } from 'tummylab-protobuf/js/program.v1/program_config_pb';
import { MediaItem } from 'tummylab-protobuf/js/program.v1/media_item_pb';
import Sortable from 'sortablejs';

import ErrorNotification from 'components/notification/error';
import EditMediaItem from 'components/edit_media_item/';
import { FormModal } from 'components/modal/form_modal';

const strings = {
  en: {
    title: "Edit program",
    cancel: "Cancel",
    save_program: "Save changes",
    preview: "Preview",
    add: "Add",
    last_edited: "Last edited",
    invalid_url: "Invalid URL",
    program: {
      tooltip: "Below you can edit basic program information.",
      version: "Version",
      version_tooltip: "Update the version of this program config.",
      type: "Type",
      type_onboarding: "Onboarding",
      type_main: "Main",
      title: "Title",
      title_tooltip: "Write down the title of the program.",
      accelerated: "This program is accelerated",
      implicit: "This program is implicit",
      image_url: "Program Image",
    },
    intro: {
      header: "Program introduction",
      tooltip: "This information is shown before users start the program.",
      no_video: "There is no intro video. You can add one.",
      add_video: "Add intro video",
      title: "Intro title",
      list_header: "Content descriptions",
      content_add: "Add new",
    },
    outro: {
      header: "Program outro",
      tooltip: "This information will be shown after users finishes the program.",
      video_header: "Video",
      no_video: "There is no outro video. You can add one.",
      add_video: "Add outro video",
      what_now_title: "What now title",
      what_now_list: "What now list",
      what_now_add: "Add item",
    },
    courses: {
      header: "Courses",
      tooltip: "Select which courses belong to the program, and in which order.",
      in_program: "In program",
      available: "Available courses",
    },
  },
  sv: {
    title: "Redigera program",
    cancel: "Avbryt",
    save_program: "Spara ändringar",
    preview: "Visa",
    add: "Lägg till",
    last_edited: "Senast redigerad",
    invalid_url: "Felaktig länk",
    program: {
      tooltip: "Nedan kan du redigera grundläggande programinformation.",
      version: "Version",
      version_tooltip: "Uppdatera programmets version.",
      type: "Typ",
      type_onboarding: "Onboarding",
      type_main: "Main",
      title: "Rubrik",
      title_tooltip: "Skriv en rubrik till programmet.",
      accelerated: "Detta programmet är accelererat",
      implicit: "Detta programmet är implicit",
      image_url: "Programbild",
    },
    intro: {
      header: "Programintroduktion",
      tooltip: "Denna informationen visas innan användare startar programmet.",
      no_video: "Det finns ingen introduktionsvideo. Du kan lägga till en.",
      add_video: "Lägg till introduktionsvideo",
      title: "Introduktionstitel",
      list_header: "Innehållsbeskrivning",
      content_add: "Lägg till ny",
    },
    outro: {
      header: "Programavslut",
      tooltip: "Denna informationen visas efter att användare gjort klart programmet.",
      video_header: "Video",
      no_video: "Det finns ingen avsultningsvideo. Du kan lägga till en.",
      add_video: "Lägg till avslutningsvideo",
      what_now_title: "Vad händer nu titel",
      what_now_list: "Vad händer nu lista",
      what_now_add: "Lägg till punkt",
    },
    courses: {
      header: "Kurser",
      tooltip: "Välj vilka kurser som tillhör programmet, och i vilken ordning.",
      in_program: "I programmet",
      available: "Tillgängliga kurser",
    },
  }
}

export default function Setup() {
  const container = document.createElement("div");
  const programUUID = require('querystring').parse(window.location.search.substring(1)).program_uuid || null
  if (programUUID === null) {
    container.appendChild(ErrorNotification(localize({ en: "No program specified", sv: "Inget program angivet"}), false));
    return container;
  }
  container.innerHTML = template({
    t: localize(strings),
  });

  container.querySelectorAll(".tooltip-text").forEach((match) => {
    match.innerText = match.innerText.trim();
  });

  const progressBar = container.querySelector(".progress");

  getProgram(programUUID)
  .then((program) => {
    populateProgram(container, program);
  })
  .catch((err) => {
    console.log("Failed to get program:", err);
    const errText = localize({en: "Failed to load program: ", sv: "Kunde inte ladda in programmet: "}) + err;
    container.replaceChildren(ErrorNotification(errText, false));
  })
  .finally(() => progressBar.classList.add("is-hidden"));

  return container;
}

function populateProgram(container, program) {
  const config = program.getConfig();
  let didChange = false;

  addOnNavigatedCallback((page) => {
    if (!didChange || (didChange && confirm(localize({en: "You have unsaved changes, these will be discarded.", sv: "Du har ändringar som inte sparats. Dessa kommer kastas bort."})))) {
      deleteProgramLock(program.getUuid())
        .catch((err) => console.log("Could not delete program lock:", err))
        .finally(() => navigateTo(page))
      return true;
    } else {
      return false;
    }
  });

  window.addEventListener("beforeunload", (ev) => {
    console.log("Unloading page");
    deleteProgramLock(program.getUuid())
      .catch((err) => console.log("Could not delete program lock:", err))
  });

  container.querySelectorAll(".edit-cancel").forEach((match) => match.addEventListener("click", (ev) => {
    navigateTo("programs");
  }));

  let saveStatusTimer;
  container.querySelectorAll(".edit-save").forEach((match) => match.addEventListener("click", (ev) => {
    container.querySelectorAll(".edit-save").forEach((match) => match.classList.add("is-loading"));
    patchProgram(program.getUuid(), config).then(() => { return patchProgramCourses(program.getUuid(), program.getCourseUuidsList()) })
    .then(() => {
      console.log("Program saved")
      didChange = false;
      container.querySelector(".errors-container").removeAllChildren();
      container.querySelector("#last-edited-text").innerText =  (new Date()).toISODateStringWithTime();
      container.querySelectorAll(".edit-status").forEach((match) => {
        const statusText = localize({
          en: "Successfully saved at ",
          sv: "Sparades korrekt kl. "
        });
        match.innerText = statusText + (new Date).toTimeString();
        if (saveStatusTimer !== undefined) {
          clearTimeout(saveStatusTimer);
        }
        saveStatusTimer = setTimeout(() => {
          container.querySelectorAll(".edit-status").forEach((match) => { match.innerText = "" });
        }, 1000 * 60 * 30 /* After 30 minutes, clear the status text */);
      });
    })
    .catch((err) => {
      console.log("Failed to save program:", err)
      const errMsg = localize({en: "The program could not be saved!", sv: "Programmet kunde inte sparas!"}) + "\n\n" + err.message
      container.querySelector(".errors-container").appendChild(ErrorNotification(errMsg));
    })
    .finally(() => container.querySelectorAll(".edit-save").forEach((match) => match.classList.remove("is-loading")))
  }));

  container.querySelector("#last-edited-text").innerText =  (new Date(program.getLastEditedAt() * 1000)).toISODateStringWithTime();

  // BASIC INFORMATION
  container.querySelector("#program-title").value = config.getName();
  container.querySelector("#program-title").addEventListener("change", (ev) => {
    console.log("Updated title to:", ev.target.value);
    config.setName(ev.target.value);
    didChange = true;
    refreshLock(program.getUuid());
  });

  container.querySelector("#program-version").value = config.getVersion();
  container.querySelector("#program-version").addEventListener("change", (ev) => {
    console.log("Updated version to:", ev.target.value);
    config.setVersion(ev.target.value);
    didChange = true;
    refreshLock(program.getUuid());
  });

  container.querySelector("#program-type").value = programTypeEnumToString(config.getType());
  container.querySelector("#program-type").addEventListener("change", (ev) => {
    console.log("Updated program type to:", ev.target.value);
    config.setType(programTypeStringToEnum(ev.target.value));
    didChange = true;
    refreshLock(program.getUuid());
  });

  if (config.getIsAcceleratedProgram()) {
    container.querySelector("#program-is-accelerated").setAttribute("checked", "checked");
  }
  container.querySelector("#program-is-accelerated").addEventListener("change", (ev) => {
    console.log("Setting is accelerated", ev.target.checked);
    config.setIsAcceleratedProgram(ev.target.checked);
    didChange = true;
    refreshLock(program.getUuid());
  });

  if (config.getIsImplicitProgram()) {
    container.querySelector("#program-is-implicit").setAttribute("checked", "checked");
  }
  container.querySelector("#program-is-implicit").addEventListener("change", (ev) => {
    console.log("Setting is implicit", ev.target.checked);
    config.setIsImplicitProgram(ev.target.checked);
    didChange = true;
    refreshLock(program.getUuid());
  });

  container.querySelector("#program-image-url").value = config.getImageUrl();
  container.querySelector("#program-preview-image").setAttribute("src", config.getImageUrl());
  container.querySelector("#program-preview-image").addEventListener("error", (ev) => {
    container.querySelector("#program-image-url").classList.add("is-danger");
    container.querySelector("#program-image-url-error").classList.remove("is-hidden");
    if (ev.target.currentSrc.trim() == "") {
      container.querySelector("#program-preview-image").setAttribute("src", MissingImage);
    }
    console.log("Failed to load image:", ev);
  });
  container.querySelector("#program-preview-image").addEventListener("load", (ev) => {
    container.querySelector("#program-image-url").classList.remove("is-danger");
    container.querySelector("#program-image-url-error").classList.add("is-hidden");
  });
  container.querySelector("#program-image-url").addEventListener("change", (ev) => {
    console.log("Updated image URL to:", ev.target.value);
    config.setImageUrl(ev.target.value);
    container.querySelector("#program-preview-image").setAttribute("src", ev.target.value);
    didChange = true;
    refreshLock(program.getUuid());
  });

  // PROGRAM INTRO
  function refreshIntroVideoViews() {
    if (config.getIntroVideo() === undefined || config.getIntroVideo() == null) {
      container.querySelector("#program-intro-video-empty").classList.remove("is-hidden");
      container.querySelector("#program-intro-video-container").classList.add("is-hidden");
    } else {
      container.querySelector("#program-intro-video-empty").classList.add("is-hidden");
      container.querySelector("#program-intro-video-container").classList.remove("is-hidden");
      const introVideoView = EditMediaItem({
        mediaItem: config.getIntroVideo(),
        isAdmin: true,
      });
      const deleteButton = document.createElement("a");
      deleteButton.classList.add("button", "is-danger", "is-outlined", "is-pulled-right");
      deleteButton.innerText = localize({en: "Delete", sv: "Ta bort"});
      container.querySelector("#program-intro-video-container").removeAllChildren();
      container.querySelector("#program-intro-video-container").appendChild(deleteButton);
      container.querySelector("#program-intro-video-container").appendChild(introVideoView);

      deleteButton.addEventListener("click", (ev) => {
        if (!confirm(localize({
          en: "Are you sure you want to delete the intro video?",
          sv: "Är du säker på att du vill ta bort introduktionsvideon?"
        }))) {
          return;
        }

        config.clearIntroVideo();
        didChange = true;
        refreshLock(program.getUuid());
        refreshIntroVideoViews();
      });
    }
  }
  refreshIntroVideoViews();

  container.querySelector("#program-intro-add-video").addEventListener("click", (ev) => {
    console.log("Create new intro video");
    const resource = new MediaItem();
    resource.setUuid(uuidv4());
    resource.setType(MediaItem.MediaItemType.VIDEO);
    config.setIntroVideo(resource);
    didChange = true;
    refreshLock(program.getUuid());
    refreshIntroVideoViews();
  });

  container.querySelector("#program-intro-title").value = config.getIntroTitle();
  container.querySelector("#program-intro-title").addEventListener("change", (ev) => {
    console.log("Set intro title to:", ev.target.value);
    config.setIntroTitle(ev.target.value);
    didChange = true;
    refreshLock(program.getUuid());
  });

  const contentDescList = container.querySelector("#program-intro-content-list");
  function setContentDescriptions() {
    const items = [];
    for (let i = 0; i < contentDescList.children.length; i++) {
      const child = contentDescList.children[i];
      const introDesc = new Program.IntroContentDescription();
      introDesc.setUuid(child.querySelector(".desc-uuid").innerText);
      introDesc.setTitle(child.querySelector(".desc-title").innerText);
      introDesc.setDescription(child.querySelector(".desc-desc").innerText);
      introDesc.setImageUrl(child.querySelector(".desc-image-url").getAttribute("src"));
      introDesc.setRequiresClinicalReport(!child.querySelector(".requires-clinical-report").classList.contains("is-hidden"));
      items.push(introDesc);
    }
    console.log("Setting content descriptions list to:", items);
    config.setIntroContentDescriptionsList(items);
    didChange = true;
    refreshLock(program.getUuid());
  }
  function addContentDescriptionToList(desc) {
    const item = document.createElement("div");
    item.classList.add("box");
    item.innerHTML = contentDescItem({
      uuid: desc.getUuid(),
      title: desc.getTitle(),
      description: desc.getDescription(),
      image_url: desc.getImageUrl(),
      clinical_report: localize({en: "Clinical report", sv: "Klinisk rapport"}),
    });
    if (desc.getRequiresClinicalReport()) {
      item.querySelector(".requires-clinical-report").classList.remove("is-hidden");
    }
    item.querySelector(".button").addEventListener("click", (ev) => {
      const fields = [
        {
          key: "title",
          label: localize({en: "Title", sv: "Titel"}),
          prefill: desc.getTitle(),
        },
        {
          key: "desc",
          label: localize({en: "Description", sv: "Beskrivning"}),
          prefill: desc.getDescription(),
        },
        {
          key: "image_url",
          label: localize({en: "Image URL", sv: "Bildlänk"}),
          prefill: desc.getImageUrl(),
        }
      ];
      const editModal = new FormModal(container, fields, {
        title: localize({en: "New intro description", sv: "Ny beskrivning"}),
        description: localize({en: "Create a new intro content description.", sv: "Skapa en ny introduktionsbeskrivning."}),
        positiveButton: localize({en: "Save", sv: "Spara"}),
        neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
        negativeButton: localize({en: "Delete", sv: "Ta bort"}),
        onSubmit: (values) => {
          desc.setTitle(values["title"]);
          desc.setDescription(values["desc"]);
          desc.setImageUrl(values["image_url"]);
          const requires = editModal.view.querySelector(".desc-requires-clinical").checked;
          desc.setRequiresClinicalReport(requires);
          contentDescList.removeAllChildren();
          config.getIntroContentDescriptionsList().forEach((desc) => {
            addContentDescriptionToList(desc);
          });
          didChange = true;
          refreshLock(program.getUuid());
        },
        onNegative: () => {
          if (!confirm(localize({
            en: "Are you sure you want to delete this description?",
            sv: "Är du säker på att du vill ta bort denna beskrivningen?",
          }))) {
            return;
          }
          for (let i = 0; i < contentDescList.children.length; i++) {
            const child = contentDescList.children[i];
            if (child.querySelector(".desc-uuid").innerText == desc.getUuid()) {
              child.remove();
              break;
            }
          }
          setContentDescriptions();
        }
      });
      const checkField = createRequiresClinicalReportField();
      editModal.addContent(checkField);
      if (desc.getRequiresClinicalReport()) {
        checkField.querySelector("input").setAttribute("checked", "checked");
      }
      editModal.show();
    });
    contentDescList.appendChild(item);
  }
  config.getIntroContentDescriptionsList().forEach((desc) => {
    addContentDescriptionToList(desc);
  });
  container.querySelector("#program-intro-content-add").addEventListener("click", (ev) => {
    const fields = [
      {
        key: "title",
        label: localize({en: "Title", sv: "Titel"}),
      },
      {
        key: "desc",
        label: localize({en: "Description", sv: "Beskrivning"}),
      },
      {
        key: "image_url",
        label: localize({en: "Image URL", sv: "Bildlänk"}),
      }
    ];
    const newModal = new FormModal(container, fields, {
      title: localize({en: "New intro description", sv: "Ny beskrivning"}),
      description: localize({en: "Create a new intro content description.", sv: "Skapa en ny introduktionsbeskrivning."}),
      positiveButton: localize({en: "Save", sv: "Spara"}),
      neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
      onSubmit: (values) => {
        const newDesc = new Program.IntroContentDescription();
        newDesc.setUuid(uuidv4());
        newDesc.setTitle(values["title"]);
        newDesc.setDescription(values["desc"]);
        newDesc.setImageUrl(values["image_url"]);
        const requires = newModal.view.querySelector(".desc-requires-clinical").checked;
        newDesc.setRequiresClinicalReport(requires);
        addContentDescriptionToList(newDesc);
        setContentDescriptions();
      }
    });
    newModal.addContent(createRequiresClinicalReportField());
    newModal.show();
  });
  Sortable.create(contentDescList, {
    animation: 150,
    onEnd: () => {
      setContentDescriptions();
    },
  });

  // PROGRAM OUTRO
  function refreshOutroVideoViews() {
    if (config.getEndOfProgramVideo() === undefined || config.getEndOfProgramVideo() == null) {
      container.querySelector("#program-outro-video-empty").classList.remove("is-hidden");
      container.querySelector("#program-outro-video-container").classList.add("is-hidden");
    } else {
      container.querySelector("#program-outro-video-empty").classList.add("is-hidden");
      container.querySelector("#program-outro-video-container").classList.remove("is-hidden");
      const outroVideoView = EditMediaItem({
        mediaItem: config.getEndOfProgramVideo(),
        isAdmin: true,
      });
      const deleteButton = document.createElement("a");
      deleteButton.classList.add("button", "is-danger", "is-outlined", "is-pulled-right");
      deleteButton.innerText = localize({en: "Delete", sv: "Ta bort"});
      container.querySelector("#program-outro-video-container").removeAllChildren();
      container.querySelector("#program-outro-video-container").appendChild(deleteButton);
      container.querySelector("#program-outro-video-container").appendChild(outroVideoView);

      deleteButton.addEventListener("click", (ev) => {
        if (!confirm(localize({
          en: "Are you sure you want to delete the outro video?",
          sv: "Är du säker på att du vill ta bort avslutningsvideon?"
        }))) {
          return;
        }

        config.clearEndOfProgramVideo();
        didChange = true;
        refreshLock(program.getUuid());
        refreshOutroVideoViews();
      });
    }
  }
  refreshOutroVideoViews();

  container.querySelector("#program-outro-add-video").addEventListener("click", (ev) => {
    console.log("Create new outro video");
    const resource = new MediaItem();
    resource.setUuid(uuidv4());
    resource.setType(MediaItem.MediaItemType.VIDEO);
    config.setEndOfProgramVideo(resource);
    didChange = true;
    refreshLock(program.getUuid());
    refreshOutroVideoViews();
  });

  container.querySelector("#program-what-now-title").value = config.getEndOfProgramWhatNowTitle();
  container.querySelector("#program-what-now-title").addEventListener("change", (ev) => {
    console.log("Set what now title to:", ev.target.value);
    config.setEndOfProgramWhatNowTitle(ev.target.value);
    didChange = true;
    refreshLock(program.getUuid());
  });

  const whatNowList = container.querySelector("#program-what-now-list");
  function setWhatNowContent() {
    const items = [];
    for (let i = 0; i < whatNowList.children.length; i++) {
      const text = whatNowList.children[i].querySelector(".dragged-text").innerText;
      items.push(text);
    }
    console.log("Setting what nows list to:", items);
    config.setEndOfProgramWhatNowsList(items);
    didChange = true;
    refreshLock(program.getUuid());
  }
  function addWhatNowToList(text) {
    const li = document.createElement("li");
    li.classList.add("draggable-li");
    const content = document.createElement("div");
    content.innerHTML = whatNowItem({
      text: text,
    });
    content.querySelector(".delete-button").addEventListener("click", () => {
      console.log("Delete what now item:", text);
      li.remove();
      setWhatNowContent();
    });
    li.appendChild(content);
    whatNowList.appendChild(li);
  }
  config.getEndOfProgramWhatNowsList().forEach((whatNow) => {
    addWhatNowToList(whatNow);
  });
  const whatNowInput = container.querySelector("#program-what-now-input");
  whatNowInput.addEventListener("input", () => {
    whatNowInput.classList.remove("is-danger");
  });
  container.querySelector("#program-what-now-add").addEventListener("click", (ev) => {
    const text = whatNowInput.value;
    if (text.trim() != "") {
      console.log("Add new what now item:", text);
      whatNowInput.value = "";
      addWhatNowToList(text);
      setWhatNowContent();
    } else {
      whatNowInput.classList.add("is-danger");
    }
  });
  Sortable.create(whatNowList, {
    handle: '.drag-handle',
    animation: 150,
    onEnd: () => {
      setWhatNowContent();
    },
  });

  // PROGRAM COURSES
  const includedCoursesList = container.querySelector("#program-courses-included-container");
  Sortable.create(includedCoursesList, {
    group: {
      name: "courses",
    },
    animation: 150,
    onEnd: () => {
      didChange = true;
      refreshLock(program.getUuid());
      const uuids = getCourseUUIDsFromList(includedCoursesList);
      console.log("Got new course UUIDs:", uuids);
      program.setCourseUuidsList(uuids);
    },
  });
  const availableCoursesList = container.querySelector("#program-courses-available-container");
  Sortable.create(availableCoursesList, {
    group: {
      name: "courses",
    },
    animation: 150,
    onEnd: () => {
      didChange = true;
      refreshLock(program.getUuid());
      const uuids = getCourseUUIDsFromList(includedCoursesList);
      console.log("Got new course UUIDs:", uuids);
      program.setCourseUuidsList(uuids);
    },
  });
  refreshCourseLists(program, includedCoursesList, availableCoursesList);
}

function programTypeEnumToString(programType) {
  switch (programType) {
    case Program.ProgramType.ONBOARDING:
      return "onboarding";
    case Program.ProgramType.MAIN:
      return "main";
    default:
      throw "Unknown program type " + programType;
  }
}

function programTypeStringToEnum(programTypeString) {
  switch (programTypeString) {
    case "onboarding":
      return Program.ProgramType.ONBOARDING;
    case "main":
      return Program.ProgramType.MAIN;
    default:
      throw "Unknown program type " + programTypeString;
  }
}

function refreshLock(programUUID) {
  patchProgramLock(programUUID)
  .then(() => console.log("Refreshed program lock"))
  .catch((err) => console.log("Failed to refresh program lock:", err))
}

function getCourseUUIDsFromList(list) {
  const result = [];
  for (let i = 0; i < list.children.length; i++) {
    const child = list.children[i];
    const uuid = child.querySelector(".course-uuid").innerText;
    result.push(uuid);
  }
  return result;
}

function refreshCourseLists(program, includedList, availableList) {
  function addToList(course, list) {
    const div = document.createElement("div");
    div.classList.add("box");
    const p = document.createElement("p");
    p.innerText = course.getTitle();
    const uuidSpan = document.createElement("span");
    uuidSpan.classList.add("is-hidden", "course-uuid");
    uuidSpan.innerText = course.getUuid();
    div.appendChild(p);
    div.appendChild(uuidSpan);
    list.appendChild(div);
  }

  const includedCourses = program.getCourseUuidsList();

  getCourses().then((courses) => {
    const courseMap = {};
    courses.forEach((course) => {
      courseMap[course.getUuid()] = course;
      if (!includedCourses.includes(course.getUuid()) && course.getScope() == program.getScope() && course.getState() != proto.API.TMY.ConfigEditor.V1.Course.State.DELETED) {
        // Add to available list
        addToList(course, availableList);
      }
    });
    includedCourses.forEach((uuid) => {
      const course = courseMap[uuid];
      // Add to included list
      addToList(course, includedList);
    })
  });
}

function createRequiresClinicalReportField() {
  const checkField = document.createElement("div");
  checkField.classList.add("field");
  const label = document.createElement("label");
  label.classList.add("label");
  const control = document.createElement("div");
  control.classList.add("control");
  const checkLabel = document.createElement("label");
  checkLabel.classList.add("checkbox");
  const checkInput = document.createElement("input");
  checkInput.setAttribute("type", "checkbox");
  checkInput.classList.add("desc-requires-clinical");
  checkLabel.appendChild(checkInput);
  checkLabel.innerHTML += "  " + localize({en: "Requires clinical report", sv: "Kräver klinisk rapport"});
  control.appendChild(checkLabel);
  checkField.appendChild(label);
  checkField.appendChild(control);
  return checkField;
}
