import template from './index.html';
import localize from 'localize';

import { navigateTo } from 'app';
import { saveAs } from 'file-saver';

import { postProgramLock, assembleProgram } from 'api/tmyConfigEditorV1';

const strings = {
  en: {
    edit: "Edit",
    download: "Download",
    locked: "Locked",
    last_edited: "Last edited",
    being_edited: "Currently being edited by",
    scope: "Scope",
    course_count: "Course count in program",
  },
  sv: {
    edit: "Redigera",
    download: "Ladda ner",
    locked: "Låst",
    last_edited: "Senast redigerad",
    being_edited: "Redigeras just nu av",
    scope: "Scope",
    course_count: "Antal kurser i programmet",
  }
};

export default function Setup(program, lock) {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings),
    title: program.getTitle(),
    scope: program.getScope(),
    last_edited: (new Date(program.getLastEditedAt() * 1000)).toISODateStringWithTime(),
    lock_owner: lock == null ? "" : lock.getUserName(),
    course_count: program.getCoursesCount(),
  });

  const lockedIcon = container.querySelector(".icon-locked");
  const lastEditedText = container.querySelector(".last-edited-text");
  const beingEditedText = container.querySelector(".currently-editing-text");
  const downloadButton = container.querySelector(".download-program");
  const editButton = container.querySelector(".edit-program");

  downloadButton.addEventListener("click", (ev) => {
    console.log("Assemble program");
    assembleProgram(program.getUuid()).then((program) => {
      program.blob().then((blob) => {
        console.log("Downloading program config as \"program-config.bin\"");
        saveAs(blob, "program-config.bin");
      });
    });
    ev.stopPropagation();
  });

  editButton.addEventListener("click", (ev) => {
    console.log("Edit program");
    if (editButton.getAttribute("disabled") === "disabled") {
      ev.stopPropagation();
      return;
    }
    editButton.classList.add("is-loading");
    postProgramLock(program.getUuid())
    .then(() => {
      navigateTo("edit_program", { program_uuid: program.getUuid() });
    })
    .catch((err) => {
      console.log("Failed to create program lock:", err);
      alert(localize({en: "Program is locked for editing.", sv: "Programmet är låst för redigering."}));
      // TODO: Maybe allow taking over lock?
    })
    .finally(() => editButton.classList.remove("is-loading"))
    ev.stopPropagation();
  });

  if (lock != null) {
    lockedIcon.parentElement.classList.remove("is-hidden");
    lastEditedText.classList.add("is-hidden");
    beingEditedText.classList.remove("is-hidden");

    editButton.setAttribute("disabled", "disabled");
    // TODO: Maybe allow taking over lock?
  }

  return container;
}