import bodyTemplate from './media_question_body.html';
import itemTemplate from './media_question_item.html';

import localize from 'localize';
import Sortable from 'sortablejs';

import { Modal } from './modal';
import { MediaItem } from 'tummylab-protobuf/js/program.v1/media_item_pb';

export class MediaQuestionModal extends Modal {
  constructor(container, {
    description = "",
    question = new MediaItem.Question(),
    onSubmit = (submittedQuestion) => { console.log("Submitted question modal with question", submittedQuestion) },
    isEditMode = false,
    onDelete = () => {},
  } = {}) {
    if (!isEditMode) {
      super(container, {
        title: localize({en: "New question", sv: "Ny fråga"}),
        description: description,
        positiveButton: localize({en: "Add question", sv: "Lägg till fråga"}),
        neutralButton: localize({en: "Cancel", sv: "Avbryt"})
      });
    } else {
      super(container, {
        title: localize({en: "Edit question", sv: "Redigera fråga"}),
        description: description,
        positiveButton: localize({en: "Add question", sv: "Lägg till fråga"}),
        neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
        negativeButton: localize({en: "Delete", sv: "Ta bort"}),
        onNegative: onDelete,
      });
    }


    this.onSubmit = onSubmit;

    const body = document.createElement("div");
    body.innerHTML = bodyTemplate({
      questionLabel: localize({en: "Question", sv: "Fråga"}),
      timeLabel: localize({en: "Show at time", sv: "Visa vid tid"}),
      seconds: localize({en: "seconds", sv: "sekunder"}),
      answersLabel: localize({en: "Answers", sv: "Svar"}),
      addAnswer: localize({en: "Add answer", sv: "Lägg till svar"}),
    });

    body.querySelectorAll("input").forEach((match) => {
      match.addEventListener("input", () => {
        if (match.value.trim() == "") {
          match.classList.add("is-danger");
        } else {
          match.classList.remove("is-danger");
        }
      });
    });

    const answerList = body.querySelector(".media-answers-list");

    function addAnswerToList(text, isCorrect = false) {
      const li = document.createElement("li");
      li.classList.add("draggable-li");
      const content = document.createElement("div");
      content.innerHTML = itemTemplate({
        text: text,
        correct: localize({en: "Correct", sv: "Rätt"})
      });
      content.querySelector(".delete-button").addEventListener("click", () => {
        console.log("Delete list item:", text);
        answerList.removeChild(li);
      });
      content.querySelector(".correct-button").addEventListener("click", (ev) => {
        ev.target.classList.toggle("is-outlined");
      });
      if (isCorrect) {
        content.querySelector(".correct-button").classList.remove("is-outlined");
      }
      li.appendChild(content);
      answerList.appendChild(li);
    }

    const answerInput = body.querySelector(".form-answer");
    body.querySelector(".add-answer-button").addEventListener("click", () => {
      const newAnswer = answerInput.value;
      if (newAnswer.trim() != "") {
        console.log("Add new answer:", newAnswer);
        answerInput.value = "";
        addAnswerToList(newAnswer, false);
      } else {
        answerInput.classList.add("is-danger");
      }
    });

    Sortable.create(answerList, {
      handle: '.drag-handle',
      animation: 150,
    });

    body.querySelector(".form-question").value = question.getQuestion();
    if (question.getTime() != 0) {
      body.querySelector(".form-time").value = question.getTime();
    }
    question.getAnswersList().forEach((ans) => {
      addAnswerToList(ans.getAnswer(), ans.getIsCorrect());
    });

    this.addContent(body);

    super.onPositive = () => {
      console.log("Add media question");
      const questionText = body.querySelector(".form-question").value;
      const timeText = body.querySelector(".form-time").value;

      const answers = [];
      body.querySelectorAll(".media-answer").forEach((match) => {
        const ans = match.querySelector(".dragged-text").innerText;
        const isCorrect = !match.querySelector(".correct-button").classList.contains("is-outlined");
        answers.push({
          answer: ans,
          correct: isCorrect,
        });
      });

      let hasEmpty = false;
      if (questionText.trim() == "") {
        body.querySelector(".form-question").classList.add("is-danger");
        hasEmpty = true;
      }

      if (timeText.trim() == "") {
        body.querySelector(".form-time").classList.add("is-danger");
        hasEmpty = true;
      }

      if (hasEmpty) {
        return;
      }

      let hasCorrect = false;
      answers.forEach((ans) => {
        hasCorrect = hasCorrect || ans.correct;
      });

      if (!hasCorrect) {
        alert(localize({
          en: "You must add at least one correct answer!",
          sv: "Du måste lägga till minst ett rätt svar!"
        }));
        return;
      }

      question.setQuestion(questionText);
      question.setTime(timeText);
      question.clearAnswersList();
      answers.forEach((ans) => {
        const newAnswer = new MediaItem.Question.Answer();
        newAnswer.setAnswer(ans.answer);
        newAnswer.setIsCorrect(ans.correct);
        question.addAnswers(newAnswer);
      });

      this.onSubmit(question);
      this.destroy();
    }
  }
}